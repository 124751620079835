<template>
  <div class="login">
    <img class="logo" width="100" height="100" src="../../assets/images/logo3.png" alt="logo" draggable="false"
      οndragstart="return false" />
    <h1 class="title">
      <div style="text-shadow: 2px 2px 1px #000">销售过程管理系统</div>
      <div style="font-size: 15px">
        <!-- <div style="margin-bottom: 6px; text-shadow: 2px 2px 1px #000">
          售前咨询: 400-1886-529 15982099479
        </div> -->
        <div style="margin-bottom: 6px; text-shadow: 2px 2px 1px #000">
          售后咨询: 400-8096-985 15982099479
        </div>
      </div>
    </h1>
    <h1 class="bottom-text">
      <!-- <img
        class="logo"
        width="100"
        height="100"
        src="../../assets/images/logo2.png"
        alt="logo"
      /> -->

      <div style="font-size: 16px">
        <span v-for="(v, i) in linkArr">
          <el-link :href="v.src" style="color: #fff; text-shadow: 2px 2px 1px #000" target="_blank">{{
            v.title }}</el-link>
          <el-divider direction="vertical" v-if="i != linkArr.length - 1"></el-divider>
        </span>
      </div>

      <div style="font-size: 14px; text-shadow: 2px 2px 1px #000">
        电信增值业务许可证编号: B2-20211017
      </div>
      <div style="font-size: 14px; text-shadow: 2px 2px 1px #000">
        信息安全管理体系认证证书编号: NOA2203629
      </div>
      <div style="font-size: 14px; text-shadow: 2px 2px 1px #000">
        实用新型专利证书专利号: ZL 2021 2 2767206.5
      </div>
      <div style="font-size: 14px; text-shadow: 2px 2px 1px #000">
        <el-link href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #fff; text-shadow: 2px 2px 1px #000"
          target="_blank">Copyright ©2022 All rights reserved. 蜀ICP备2021005692号-2</el-link>

      </div>
    </h1>
    <div class="box">

      <div class="login_form">
        <div class="form">
          <div class="ms-title">商机云后台管理系统</div>
          <div class="head">
            <span :class="tabType == 'yzm' ? 'tab_active' : ''" @click="changeTab('username', true)">用户登录</span>
            <img src="" alt="" />
            <span :class="tabType == 'username' ? 'tab_active' : ''" @click="changeTab('yzm', true)">管理员登录</span>
          </div>
          <el-form :model="param" :rules="rules" ref="param" class="param" v-if="tabType == 'username'">
            <el-form-item prop="username">
              <el-input v-model="param.username" placeholder="账号" prefix-icon="el-icon-mobile-phone"
                @input="getUserName"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="param.password" placeholder="密码" prefix-icon="el-icon-lock" @input="getPwd"
                show-password></el-input>
            </el-form-item>

            <el-form-item prop="identity" class="identity">
              <el-input v-model="identity" prefix-icon="el-icon-tickets" placeholder="验证码 " style="margin-right: 10px"
                @input="getUserCode"></el-input>
              <div @click="handleRefreshCode()">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
            </el-form-item>
          </el-form>
          <el-form :model="param" :rules="phoneRules" ref="param" class="param" v-if="tabType == 'yzm'">
            <el-form-item prop="username">
              <el-input v-model="param.username" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone" maxlength="11"
                @input="phoneBtn"></el-input>
            </el-form-item>
            <el-form-item prop="msgCode" class="time">
              <el-input v-model="param.code" placeholder="验证码" prefix-icon="el-icon-tickets"
                @input="logCode(param.code)"></el-input>
              <span v-show="!show" class="count" style="color: red">{{ count }} s</span>
              <el-button class="yzm" type="primary" plain @click="getLoginCode" v-show="show">获取验证码</el-button>
            </el-form-item>

            <el-form-item prop="identity" class="identity">
              <el-input v-model="identityB" prefix-icon="el-icon-tickets" placeholder="验证码" style="margin-right: 10px"
                @input="getUserCodeB"></el-input>
              <div @click="handleRefreshCodeB()">
                <Identify :identifyCode="identifyCodeB"></Identify>
              </div>
            </el-form-item>
          </el-form>
          <div class="btns">
            <el-button type="primary" @click="submitForm" :disabled="identityBol">立即登录</el-button>
            <div style="margin-top: 6px;display: flex;">
              <p style="width: 0;padding: 0;margin-right: 10px;margin-top: 10px;">tip:</p>
              <p style="width: 100%;line-height: 1.5;margin-top: 10px;">建议使用Edge、谷歌、火狐、双核等浏览器打开本网站</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-card class="box-card" style="left: 4%; width: 600px;" v-if="statement">
      <div slot="header" class="clearfix">
        <span style="font-size: 20px;font-weight: bold;">声明书</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="statement = false">知道了</el-button>
      </div>
      <div style="line-height: 1.5;font-size: 15px;font-family: 'Helvetica Neue',Helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei','微软雅黑',Arial,sans-serif;">
        尊敬的客户：
        ​ <div style="text-indent: 30px;">我司网站自 2023 年4月24 号凌晨7点开始到我司自发声明时遭受了同行黑客的攻击导致我司 APP 和管理后台，长时间瘫痪无法正常使用，该黑客对我司的用户使用情况特别了解，特别针对性在在我司客户工作时间 9: 00-12: 00 点，13:30-18:30 进行攻击，我司母换一次域名和 P 将管理后台城名发送给我司客户售后群后，该黑客都能精准的定位并攻击到我们。</div>
        ​ <div style="text-indent: 30px;">
          我们已经积极采取了各种措施保护网站的安全，同时已经将事件移交网警部门，希望能够追查黑客的身份并追究其法律责任。对于此次事件给客户带来的损失，我们将尽快进行补偿，给予您合理的赔偿。</div>
        ​ <div style="text-indent: 30px;">针对该情况已向辖区派出所进行报案，辖区派出所已经向分局网警上报，并受理此案。</div>
        ​ <div style="text-indent: 30px;">我司秉承违法必究的态度，将对此事件追究到底。</div>
        ​ <div style="text-indent: 30px;">与此同时，我司已经邀请了华为云安全防护团队和第三方专业安全防护团队对我司服务器进行防护。</div>
        ​ <div style="text-indent: 30px;">该期间对用户造成的不便，我司深表歉意，也请配合我们客服团队进行沟通处理</div>
        <div style="text-indent: 30px;"> 此致，</div>
        <div style="text-indent: 30px;"> 敬礼！</div>

        ​ <div style="text-indent: 30px;text-align: right;">四川商机通信息技术咨询有限公司</div>
        ​ <div style="text-indent: 30px;text-align: right;">2023 年4月 26 日</div>
      </div>
    </el-card> -->
    <!-- <el-card class="box-card" style="left: 4%;width: 30%;" v-if="statement">
      <div slot="header" class="clearfix">
        <span style="font-size: 20px;font-weight: bold;">声明书</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="statement = false">知道了</el-button>
      </div>
      <el-image :src="statementSrc" style="width: 100%;" :preview-src-list="[statementSrc]"></el-image>
    </el-card>
    <el-card class="box-card" style="right: 3%;width: 24%;" v-if="receiptBool">
      <div slot="header" class="clearfix">
        <span style="font-size: 20px;font-weight: bold;">回执单</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="receiptBool = false">隐藏</el-button>
      </div>
      <el-image :src="receiptSrc" style="width: 100%;" :preview-src-list="[receiptSrc]"></el-image>
    </el-card> -->

    <!-- 验证账号弹出框 -->
    <el-dialog title="验证账号" :visible.sync="codeShow" top="10%" width="30%" class="codeDialog">
      <div class="code-content">
        <h3>获取验证码激活该账户</h3>
        <div>请输入短信收到的验证码</div>
        <div class="code-input">
          <input type="text" v-model="codeNum" placeholder="请输入验证码" />
          <button @click="codeNum = null">清空</button>
        </div>
      </div>
      <div class="code-footer">
        <el-button type="primary" :disabled="resetCodeBool" v-if="!resetCodeBool" plain
          @click="revalidation">获取验证码</el-button>
        <el-button type="primary" @click="inputCode" v-if="validateBool">确定</el-button>
        <el-button type="primary" :loading="true" v-else>验证中</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { getLogin, getCode, getLoginNoIp, updateStatus, findStatus } from "@/api/login";
import { findEncryption } from "@/api/phone-encryption";
import { customFindOne, customAddUpdate, customDel } from "@/api/phoneRecycle";
import { seatPhoneGetCode } from "@/api/seat-phone";

import Identify from "./modules/Identity.vue";

export default {
  name: "login",
  components: { Identify },
  data() {
    return {
      validateBool: true,
      resetCodeBool: false,
      userArr: ['test', '17621555510', '15683343086', '19157785215'],
      codeNum: null,
      codeShow: false,
      newColumnsData: [{
        label: '归属人',
        prop: 'userRealname',
        key: 1
      }, {
        label: '企业名称',
        prop: 'company',
        key: 2
      }, {
        label: '姓名',
        prop: 'customerPhone',
        key: 3
      }, {
        label: '行业',
        prop: 'industry',
        key: 4
      }, {
        label: '地址',
        prop: 'address',
        key: 5
      }, {
        label: '导入时间',
        prop: 'createTime',
        key: 6
      },
      {
        label: '更新时间',
        prop: 'getTime',
        key: 7
      },
      {
        label: '拨打时间',
        prop: 'changeTime',
        key: 8
      }, {
        label: '备注',
        prop: 'remarks',
        key: 9
      }, {
        label: '决策人',
        prop: 'choosePeople',
        key: 10
      }, {
        label: '公司规模',
        prop: 'customerScale',
        key: 11
      }, {
        label: '客户来源',
        prop: 'customerSource',
        key: 12
      }],
      receiptSrc: require('@/assets/images/receipt.jpg'),
      statementSrc: require('@/assets/images/statement.png'),
      statement: true,
      receiptBool: true,
      linkArr: [
        {
          title: "关于我们",
          // src: "http://www.cddmt.cn/zn/list-7-1.html",
          src: "http://www.cddmt.cn/index.html",
        },
        {
          title: "探客躺赚",
          src: "/#/",
        },
      ],
      lastIp: null,
      identityBol: true,
      identity: "",
      identityB: "",
      identifyCode: "", // 验证码初始值
      identifyCodeB: "", // 验证码初始值
      identifyCodes: "1234567890", // 验证码的随机取值范围
      show: true,
      count: "",
      timer: null,
      tabType: "username",
      code: "",
      realname: null,
      rules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        msgCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      param: {
        username: null,
        password: null,
        code: null,
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      phoneRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "长度应为11位", trigger: "blur" },
        ],
        msgCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      drawer: false,
      regForm: {
        username: "",
        email: "",
        pass: "",
        checkPass: "",
        companyName: "",
        realname: "",
      },
      customerSourceOptions:['转介绍','网络开发','探客推广','抖音推广','百度推广','电话开发']
    };
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      // document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      // document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      // document.addEventListener("keydown", function (e) {
      //   if (e.key == "F12") {
      //     e.preventDefault(); // 如果按下键F12,阻止事件
      //   }
      // });
      localStorage.setItem('customerSourceOptions',JSON.stringify(this.customerSourceOptions))
    
      this.getIp();
      // sessionStorage.setItem("ip", returnCitySN["cip"]);
      this.$store.commit("clearTags");
      // this.lastIp = sessionStorage.getItem("ip");
      // console.log("  this.lastIp ", this.lastIp);
      // if (this.lastIp) {
      //   Message.success("IP地址获取成功");
      // } else {
      //   Message.error("IP地址获取失败");
      // }
    });
  },
  mounted() {
    this.handleRefreshCode();
    this.handleRefreshCodeB();
  },
  methods: {
    //重新验证按钮
    revalidation() {
      this.resetCodeBool = true
      let params = {
        activePhone: this.param.username,
      };
      let res = seatPhoneGetCode(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      });

    },
    async inputCode() {
      if (!this.codeNum) {
        Message.warning("验证不能为空~");
        return false
      }
      this.validateBool = false
      setTimeout((v) => {
        this.validateBool = true
      }, 3000)

      let params = {
        "activePhone": this.param.username,
        "code": this.codeNum
      };
      let res = await updateStatus(params);
      if (res.data.statusCode == "00000") {
        this.codeShow = false
        Message.success(res.data.message);
        await this.logined(res)
      } else {
        Message.error(res.data.message);
      }

    },
    // 获取IP地址
    test() {
      // 1
      // var obj = { a: 1, b: 2 };
      // Object.setPrototypeOf(obj, { c: 3 });
      // Object.defineProperty(obj,'d',{value: 4, enumerable: false})
      // for(let prop in obj){
      //     console.log("666",prop)
      // }

      // 2
      // setTimeout(function () {
      //   console.log('007',1);
      // }, 0);
      // new Promise(function execulor(resolve) {
      //   console.log('007',2);
      //   for (var i = 0; i < 10000; i += 1) {
      //     i == 9999 && resolve();
      //   }
      //   console.log('007',3);
      // }).then(function () {
      //   console.log('007',4);
      // });
      // console.log('007',5);

      // 3
      // var name = "window";
      // var person = {
      //   name: "person",
      //   sayName: function () {
      //     console.log('666',this.name);
      //   },
      // };
      // function sayName() {
      //   var sss = person.sayName;
      //   sss();

      //   person.sayName();
      //   person.sayName();
      //   (b = person.sayName)();
      // }
    },
    getIp() {
      let _this = this;
      var data = { key: "UUKBZ-LA7LR-72RW6-WSFZS-JXDTJ-TZBZ2" }; //ip缺省时会自动获取请求端的公网IP,
      var url = "https://apis.map.qq.com/ws/location/v1/ip";
      data.output = "jsonp";
      $.ajax({
        type: "get",
        dataType: "jsonp",
        data: data,
        jsonp: "callback",
        url: url,
        success: function (json) {
          // console.log("  _json ", json.result.ip);
          // array.push(
          //   json.result.ad_info.province +
          //     json.result.ad_info.city +
          //     json.result.ad_info.district
          // ); //省市区
          // array.push(json.result.location.lat); //经度
          // array.push(json.result.location.lng); //纬度
          // console.log("array", array);
          console.log("json", json);
          sessionStorage.setItem("ip", json.result.ip);
          _this.lastIp = sessionStorage.getItem("ip");
          console.log("  _this.lastIp ", _this.lastIp);
          if (_this.lastIp) {
            // Message.success("IP地址获取成功");
          } else {
            // Message.error("IP地址获取失败");
          }
        },
        error: function (err) {
          //业务处理
          // Message.error("IP地址获取失败");
        },
      });
    },
    getCookie(name) {
      const cookieArr = document.cookie.split("; ");
      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (cookiePair[0] === name) {
          return cookiePair[1];
        }
      }
      return JSON.stringify([]);
    },
    saveNewColumnsData() {
      if (this.getCookie("columnsData")) {
        let oldObj = JSON.parse(this.getCookie("columnsData"))
        if (oldObj.length != 0) return false
      }

      const columnsData = this.newColumnsData
      const jsonStr = JSON.stringify(columnsData);

      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 10); // 设置为未来10年

      document.cookie = `columnsData=${jsonStr}; expires=${futureDate.toUTCString()}; path=/`;

    },
    async setCustomDiv(roleName, id, parentId) {
      let params = {
      };
      if (roleName == "企业") {
        params.parentId = id
      } else {
        params.parentId = parentId
      }

      let res = await customFindOne(params);
      let data = res.data.data


      this.newColumnsData.forEach((v1) => {
        data.forEach((v2) => {
          if (v2.name == v1.label) {
            v1.label = v2.value
          }
        })
      })
      await this.saveNewColumnsData()
      // localStorage.setItem("newColumnsData", JSON.stringify(this.newColumnsData));
    },
    // 输入验证码
    // 账户登录
    getUserCode() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 账户输入
    getUserName() {
      this.param.username = this.param.username.replace(/\s*/g, "");
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 密码输入
    getPwd() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 验证码登录
    getUserCodeB() {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identifyCodeB == this.identityB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 刷新验证码
    handleRefreshCode() {
      this.identifyCode = "";
      this.identityBol = true
      this.makeCode(this.identifyCodes, 4);
    },
    handleRefreshCodeB() {
      this.identifyCodeB = "";
      this.makeCodeB(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
      console.log("这是验证码", this.identifyCode);
    },
    makeCodeB(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCodeB += data[this.randomNum(0, data.length - 1)];
      }
      // console.log("这是验证码", this.identifyCode);
    },

    phoneBtn() {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identityB == this.identifyCodeB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    logCode(v) {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identityB == this.identifyCodeB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }

      console.log("打印验证码登录", this.identityBol);
    },
    changeTab(type, bol) {
      this.identity = "";
      this.identityBol = bol;
      this.tabType = type;
      this.param.username = "";
      this.param.code = "";
      this.param.password = "";
    },
    openModle() {
      this.drawer = true;
    },
    getHideNum() {
      let params = {};
      let res = findEncryption(params);
      res.then((res) => {
        localStorage.setItem("hideNum", res.data.data);
      });
    },
    async loginApi(params) {

      // 下面的企业可以通过账号密码来登录
      let userArr = this.userArr
      let res
      if (userArr.includes(this.param.username)) {
        res = await getLoginNoIp(params);
      } else {
        res = await getLogin(params);
      }
      await this.logined(res)
    },
    async logined(res) {
      if (res.data.statusCode == "00000") {

        // 云南除昆明地区的登录限制
        // let ipInfo = res.data.data.ip
        // let arr = [11953, 13361]
        // let GSid = null
        // if (res.data.data.roleName == '企业') {
        //   GSid = res.data.data.user.id
        // } else {
        //   GSid = res.data.data.user.parentId
        // }
        // if ((arr.includes(GSid))) {
        //   console.log("需要验证地区");
        //   if (ipInfo) {
        //     if (ipInfo.province == '云南' && ipInfo.city != '昆明') {
        //       Message.warning('禁止在云南省的大部分地区登陆系统!');
        //       return false
        //     }
        //   }
        // }


        // Message.success("登录成功");
        localStorage.setItem("ms_username", this.param.username);
        localStorage.setItem("id", res.data.data.user.id);
        localStorage.setItem("realname", res.data.data.user.realname);
        localStorage.setItem("TOKEN", res.data.data.token);
        localStorage.setItem("parentId", res.data.data.user.parentId);
        localStorage.setItem("roleName", res.data.data.roleName);
        localStorage.setItem("role", res.data.data.user.remark);
        localStorage.setItem("companyName", res.data.data.companyName);


        // 自定义列
        let roleName = res.data.data.roleName
        let id = res.data.data.user.id
        let parentId = res.data.data.user.parentId
        // localStorage.setItem("newColumnsData", JSON.stringify(this.newColumnsData));

        if (res.data.data.roleName != '管理员') {
          await this.setCustomDiv(roleName, id, parentId)
        } else {
          await this.saveNewColumnsData()
        }

        if (res.data.data.roleName != "企业") {
          // console.log("查询隐藏号码");
          this.getHideNum();
        }
        localStorage.setItem(
          "Permission",
          JSON.stringify(res.data.data.Permission)
        );
        this.$router.push("/");
        localStorage.setItem(
          "encryption",
          JSON.stringify(res.data.data.Permission.indexOf("encryption"))
        );
      } else if (res.data.statusCode != "00000") {
        Message.error(res.data.message);
        this.identityBol = true
        // return false;
      } else {
        this.identityBol = true
        // Message.error("大迈通提醒您等待2分钟稍后重试哦!");
      }
    },
    async submitForm() {
      // 下面的企业可以通过账号密码来登录
      let userArr = this.userArr
      if (userArr.includes(this.param.username)) {

        // 直接去登录
        let params = {
          username: this.param.username,
          password: this.param.password,
          code: this.param.code,
          ipAddress: this.lastIp,
        };
        await this.loginApi(params);
        await this.handleRefreshCode();
        // 点击登录后清除部分数据
        this.param.password = "";
        this.param.code = "";
        this.identity = "";
        this.identityB = "";
      } else {
        let res = await findStatus({ activePhone: this.param.username });
        console.log("res", res.data.statusCode);
        if (res.data.statusCode == "50007") {
          // 弹出验证弹框
          this.codeShow = true
        } else if (res.data.statusCode == "50000") {
          // 弹出验证弹框
          Message.error(res.data.message);
        } else {

          // 直接去登录
          let params = {
            username: this.param.username,
            password: this.param.password,
            code: this.param.code,
            ipAddress: this.lastIp,
          };
          await this.loginApi(params);
          await this.handleRefreshCode();
          // 点击登录后清除部分数据
          this.param.password = "";
          this.param.code = "";
          this.identity = "";
          this.identityB = "";
        }
      }

    },
    //获取验证码
    getLoginCode() {
      if (this.param.username != null && this.param.username != "") {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT; // 这些参数还没定义
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }

        let params = {
          username: this.param.username,
        };
        let res = getCode(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        });
      } else {
        Message.error("请输入手机号！");
      }
    },
  },
};
</script>

<style lang='less' scoped>
.identity {
  display: flex;
  align-items: center;
}

.codeDialog {
  /deep/ .el-dialog {
    min-height: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 40px rgba(0, 0, 0, .52);

    .el-dialog__body {
      background-color: #ddd;
    }

    .code-content {
      text-align: center;
      padding: 20px;

      h3 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .code-input {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }

      .code-input input {
        flex: 1;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 10px;
      }



      .code-input button {
        border: none;
        padding: 6px 10px;
        border-radius: 4px;
        cursor: pointer;

      }


    }

    .code-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      // background-color: #f5f7fa;
    }
  }
}

.login {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/bg7.jpg);
  // background-image: url(../../assets/images/bg1.png);
  background-size: 100% 100%;

  .box-card {
    position: fixed;
    top: 20%;

    min-height: 300px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.8) !important
  }

  .logo {
    position: absolute;
    left: 4%;
    top: 5%;
  }

  >.title {
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  >.bottom-text {
    width: 80%;
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    >div {
      margin-bottom: 10px;
    }
  }
}

.param /deep/ .el-input__icon {
  height: 0;
}

.ms-title {
  /* width: 100%; */
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: rgb(63, 210, 239);
  /* border-bottom: 1px solid #ddd; */
}

.box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.login_form {
  border-radius: 5px;
  background: rgba(226, 242, 255, 0.5);
  width: 380px;
  /* height: 380px; */
  padding: 15px;
  margin-right: 50%;
  margin-top: 10%;
  transform: translateX(50%);
  box-shadow: 0 2px 12px 30px rgba(0, 0, 0, 0.05);
}

.form {
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  /* height: 380px; */
  padding-bottom: 20px;
  box-sizing: border-box;
  // gap: 20px;
  // backdrop-filter: blur(10px);
}

.head {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 5px 5px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.head>span {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #3278FE;
  color: #fff;
  cursor: pointer;
}

.head>span:nth-child(1) {
  border-radius: 5px 0 0 5px;
}

.head>span:nth-child(3) {
  border-radius: 0 5px 5px 0;
}

.head>.tab_active {
  color: #3278FE;
  background-color: #fff;
}

.yzm {
  margin-left: 20px;
}

.count {
  margin-left: 20px;
  width: 112px;
  height: 40px;
}

.param {
  padding: 20px 20px 0 20px;
}

.param /deep/ .el-form-item__content {
  margin-left: 0 !important;
}

.param {
  padding: 20px 20px 0 20px;
}

.param /deep/ .el-form-item__content {
  margin-left: 0 !important;
  display: flex;
  /* align-items: center; */
}

.param /deep/ .el-form-item__content /deep/ .el-input:nth-child(2) {
  width: 60%;
}

.param /deep/ .el-form-item__content /deep/ .el-button {
  margin-left: 25px;
}

p {
  color: #3278FE;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  width: 130px;
  margin-top: 30px;
}

.btns {
  padding: 0 20px;
  margin-top: 10px;
  /* width: 100% !important; */
  justify-content: space-around;
}

.btns /deep/ .el-button {
  width: 100%;
}
</style>
