import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import router from './router/indexOld'
import store from "./store";
import axios from "axios";
import { Message } from "element-ui";

// 将所有的console.log变为空函数 相当于注释所有的console.log
// console.log = function() {}

// 引入动态数字变化效果插件
import VueAnimateNumber from "vue-animate-number";
Vue.use(VueAnimateNumber);

// 引入iconfont.css
// import "@/assets/fonts/iconfont.css"
// 引入reset.css
import "@/assets/css/reset.css";
// 引入elementui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 引入echarts
import * as echarts from "echarts";
// 引入粒子动效
import VueParticles from "vue-particles";
Vue.use(VueParticles);
// 引入优化message
import { ElMessage } from "@/assets/js/elementui-api";
Vue.prototype.$message = new ElMessage();
// 引入百度地图（不用再到html中导入）
import baiduMap from "vue-baidu-map";
Vue.use(baiduMap, { ak: "6Fshb9PGsRQEFFUSCrGPkAGEInGLnrGC" });

// 响应拦截  判断token失效跳转
axios.interceptors.response.use(
  (response) => {
    let Message = new ElMessage();
    // console.log(response.data)
    if (response.status == "500") {
      localStorage.removeItem("TOKEN");
      this.$router.push("/login");
      console.log(response.data.message);
    } else if (response.data.statusCode == "51000") {
      router.push("/login");
      localStorage.removeItem("TOKEN");
      // alert("登录失效，请重新登录!")
      Message.warning("TOKEN失效,请重新登录");
      // console.log("saas登录失效");
      return false;
    } else if (response.data.statusCode == "51001") {
      Message.warning(response.data.message);
    } else if (response.data.statusCode == "51002") {
      Message.warning(response.data.message);
    }

    return response;
  },
  (error) => {
    Message.warning("当前网络不稳定,请稍后再试");
    router.push("/home");
    return Promise.reject(error); //返回接口返回的错误信息
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
