/**
 * 搜客宝api
 * 
 */

import request from '@/utils/request'

// 查询套餐
export const queryMeal = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/meal',
        data
    })
}

//登录 
export const skbLogin = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/login',
        data
    })
}


//获取账号信息(搜客宝) 
export const getSkbUser = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/status',
        data
    })
}

//查询账户（商机通信数据） 
export const selectAccount = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/selectAccount',
        data
    })
}

//创建账户（商机通信数据） 
export const createSkbUser= (data) => {
    return request({
        method: 'post',
        url: '/createAccount/create',
        data
    })
}

//查询当前公司已开通员工查询线索
export const selectAccountUser = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/selectAccountUser',
        data
    })
}

//升级套餐续费
export const upgradeMeala = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/meala',
        data
    })
}
//更新账户（商机通信数据）
export const updateAccount = (data) => {
    return request({
        method: 'post',
        url: '/createAccount/update',
        data
    })
}


//查信公司套餐金额设置
export const queryCompanyTc = (data) => {
    return request({
        method: 'post',
        url: '/combo/select',
        data
    })
}

//删除公司套餐金额设置
export const deleteCompanyTc = (data) => {
    return request({
        method: 'post',
        url: '/combo/delete',
        data
    })
}


//删除公司套餐金额设置
export const addCompanyTc = (data) => {
    return request({
        method: 'post',
        url: '/combo/add',
        data
    })
}

//删除公司套餐金额设置
export const updateCompanyTc = (data) => {
    return request({
        method: 'post',
        url: '/combo/update',
        data
    })
}

//查询线索数据（在线索里面领取的客户数据）
export const skbHistoryData = (data) => {
    return request({
        method: 'post',
        url: '/skb/select',
        data
    })
}


//查询领取线索的所有电话号码信息
export const selectClueId = (data) => {
    return request({
        method: 'post',
        url: '/skb/selectClueId',
        data
    })
}