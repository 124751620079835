<template>
    <div class="tags" v-if="showTags">
        <ul>
            <li class="tags-li" v-for="(item, index) in tagsList" :key="index" :class="{ 'active': isActive(item.path) }">
                <i class="el-icon-location-information" style="font-size: 16px;margin-top: 3px;"
                    v-show="isActive(item.path)"></i>

                <router-link :to="item.path" class="tags-li-title">{{ item.title }}</router-link>
                <!-- <span class="tags-li-icon" @click="closeTags(index)">
                    <i class="el-icon-close"></i>
                </span> -->
                <span v-show="index != tagsList.length - 1">></span>
            </li>
        </ul>
        <div class="tags-close-box">
            <el-dropdown @command="handleTags">
                <!-- <el-button size="mini" type="primary" style="width:10px"> -->
                <!-- 标签选项 -->
                <div class="close-con">
                    <i class="el-icon-circle-close" style="font-size: 22px;"></i>
                </div>
                <!-- </el-button> -->
                <template #dropdown>
                    <el-dropdown-menu size="mini">
                        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                        <el-dropdown-item command="all">关闭全部</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        tagsList() {
            return this.$store.state.tagsList;
        },
        showTags() {
            return this.tagsList.length > 0;
        }
    },
    methods: {
        isActive(path) {
            return path === this.$route.fullPath;
        },
        // 关闭单个标签
        closeTags(index) {
            const delItem = this.tagsList[index];
            this.$store.commit("delTagsItem", { index });
            const item = this.tagsList[index]
                ? this.tagsList[index]
                : this.tagsList[index - 1];
            if (item) {
                delItem.path === this.$route.fullPath &&
                    this.$router.push(item.path);
            } else {
                this.$router.push("/");
            }
        },
        // 关闭全部标签
        closeAll() {
            this.$store.commit("clearTags");
            this.$router.push("/");
        },
        // 关闭其他标签
        closeOther() {
            const curItem = this.tagsList.filter(item => {
                return item.path === this.$route.fullPath;
            });
            this.$store.commit("closeTagsOther", curItem);
        },
        // 设置标签
        setTags(route) {
            const isExist = this.tagsList.some(item => {
                return item.path === route.fullPath;
            });
            if (!isExist) {
                if (this.tagsList.length >= 8) {
                    this.$store.commit("delTagsItem", { index: 0 });
                }
                this.$store.commit("setTagsItem", {
                    name: route.name,
                    title: route.meta.title,
                    path: route.fullPath
                });
            }
        },
        handleTags(command) {
            command === "other" ? this.closeOther() : this.closeAll();
        }
    },
    watch: {
        $route(newValue) {
            this.setTags(newValue);
        }
    },
    created() {
        this.setTags(this.$route);
        // 关闭当前页面的标签页
        // this.$store.commit("closeCurrentTag", {
        //     $router: this.$router,
        //     $route: this.$route
        // });
    }
};
</script>


<style lang="less">
.tags {
    position: relative;
    height: 30px;
    overflow: hidden;
    background: #fff;
    padding-right: 120px;
    padding-left: 15px;
    margin-left: 1px;
    box-shadow: 0 5px 10px #ddd;
}

.tags ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.tags-li {
    float: left;
    margin: 3px 5px 2px 0;
    border-radius: 3px;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 23px;
    line-height: 23px;
    // border: 1px solid #e9eaec;
    background: #fff;
    padding: 0 5px 0 0;
    vertical-align: middle;
    color: #666;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
    background: #f8f8f8;
}

// .tags-li.active {
//     color: #fff;
// }

.tags-li-title {
    float: left;
    max-width: 80px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // margin-right: 5px;
    color: #666;
}

.tags-li.active {
    // background-color: #3278FE;
    display: flex;
    font-weight: bold;
}

// .tags-li.active>a::before{
    //     content: "";
    //     background: #fff;
    //     display: inline-block;
    //     width: 8px;
    //     height: 8px;
    //     border-radius: 50%;
    //     position: relative;
    //     margin-right: 6px;
    // }
    .tags-li.active .tags-li-title {
        //     color: #fff;
        border-bottom: 1px solid #3278FE;
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.tags-close-box {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    padding-top: 1px;
    text-align: center;
    width: 30px;
    height: 30px;
    background: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
</style>
