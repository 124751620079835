/**
 * ajax底层工具函数封装
 */

import axios from "axios";
import local from "@/utils/local";

axios.defaults.https = true;
axios.defaults.timeout = 0; // 请求超时时间，如果请求时间超过
// 0秒，不限制超时。

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // 把本地存储的token取出来
    let token = local.get("TOKEN");

    // 请求头携带token字段
    if (token) {
      config.headers["X-Access-Token"] = token;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 暴露出去
export default axios;
