/**
 * call
 * 呼叫
 */

import request from "@/utils/request";

//
export const callGetPassivePhone = (data) => {
  return request({
    method: "post",
    url: "/call/get-passivePhone",
    data,
  });
};

export const update_call = (data) => {
  return request({
    method: "post",
    url: "/phone/update_call",
    data,
  });
};

// saas
// export const callAppBind = (data) => {
//   return request({
//     method: "post",
//     url: "/call/app_bind",
//     data,
//   });
// };

// pass
export const callAppBind = (data) => {
  return request({
    method: "post",
    url: "/api/entrance/bind",
    data,
  });
};
