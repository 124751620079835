<template>
  <el-card id="sipCall">
    <main>
      <audio id="audio" v-if="showAudio" autoplay="autoplay" src="" controls></audio>
      <audio id="audioB" ref="audioB" src="../../assets/mp3/nocall.wav" controls style="display: none"></audio>
      <el-button type="danger" @click="hangup()" style="margin-left: 10px">挂断</el-button>
    </main>
  </el-card>
</template>

<script>
import { confirmUserOnline, selectDzwlUserApi } from "@/api/dzwl";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { callAppBind } from "@/api/call";
export default {
  props: ["callPhoneNum", "callPhoneData"],
  // 需要号码和sip接口参数
  data() {
    return {
      showAudio: false,
      ua: null,
      socket: null,
      eventHandlers: null,
      phoneNum: null,
      config: {},
      isRegister: false, // 是否已注册
      registerState: false,
    };
  },
  created() {
    this.ua.stop();
  },
  watch: {
    callPhoneNum(n) {
      this.phoneNum = n;
    },
  },
  mounted() {
    // 需要确认有无这些文件导入 (selectDzwlUserApi和confirmUserOnline接口,Message插件)
    //根据用户IP 返回城市级别的定位结果
    // let native = new BMap.LocalCity();
    // native.get(async (res) => {
    //   let params = {
    //     companyId: localStorage.getItem("parentId"),
    //     userId: localStorage.getItem("id"),
    //   };
    //   // 确认在不在白名单
    //   let res1 = await selectDzwlUserApi(params);
    //   let data1 = res1.data.data;
    //   if (data1.length != 0) {
    // 可以拨打的地方
    this.call();
    //   } else {
    //     // 确定用户在不在经纬度接口
    //     params = {
    //       userLongitude: res.center.lng, //围栏圆心经度
    //       userLatitude: res.center.lat, ////围栏圆心纬度
    //       companyId: localStorage.getItem("parentId"), // 公司id
    //       userId: localStorage.getItem("id"), // 用户id
    //     };
    //     let res2 = await confirmUserOnline(params);
    //     if (res2.data.statusCode == "00000") {
    //       // 可以拨打的地方
    //       this.call();
    //     } else {
    //       Message.warning("你当前的位置不在公司围栏内!");
    //       return false;
    //     }
    //   }
    // });
  },
  methods: {
    // 挂断
    hangup() {
      if (this.ua) {
        this.ua.stop();
      }
      console.log("挂断了");
      // this.ua.stop();
      let audio = document.getElementById("audio");
      audio.src = "";
      audio.play();
      let obj = {
        bool: false,
        ua: this.ua,
      };
      let audioB = document.getElementById("audioB");
      audioB.pause();
      this.$emit("showValue", obj); //自定义事件  传递值“子向父组件传值”
    },
    // 拨号
    call() {
      this.showAudio = true;
      this.phoneNum = this.callPhoneNum;
      console.log("组件的号码", this.callPhoneNum);
      this.$nextTick(async () => {
        await this.getSipInfo();
        if (!this.registerState) {
          console.log("注册事件");
          this.callPhone(); // 注册
        }
        await this.testCall();
      });
    },
    testCall(sip_phone_number_) {
      console.log("sip3");

      // Register callbacks to desired call events
      // 判断是否注册
      if (this.isRegister) {
      }
      let options = {
        eventHandlers: this.eventHandlers,
        mediaConstraints: {
          audio: true,
          video: false,
          mandatory: { maxWidth: 640, maxHeight: 360 },
        },
      };

      // let obj = {
      //   sip: "47.98.172.37:5960",
      //   wsip: "wss://h5.zzz888.cn:7443",
      //   username: "26ac631ac14213882738650",
      //   password: "28ca40e86b27c4a8f1b606587eec80f6",
      //   uri: `sip:26ac631ac14215171505290@47.98.172.37:5960`,
      // };

      console.log("this.config", this.config);
      let obj = {
        sip: this.config.sip,
        wsip: this.config.wssip,
        username: this.config.ws_user,
        password: this.config.ws_pass,
        uri: `sip:${this.config.ws_user}@${this.config.sip}`,
      };

      this.ua.call("sip:fs" + this.phoneNum + "@" + obj["sip"], options);
    },
    //sip 配置
    getUa() { },
    // 调用接口，获取sip信息
    async getSipInfo(phone) {
      // console.log("sip1");
      // this.phoneNum = "13882738650";
      // 获取主叫号码
      // let activePhone = localStorage.getItem("ms_username");
      // let parentId = localStorage.getItem("parentId");
      // let params = {
      //   activePhone: activePhone,
      //   passivePhone: this.phoneNum,
      //   parentId,
      // };
      // let res = await callAppBind(params);
      console.log("callPhoneData", this.callPhoneData);
      let res = this.callPhoneData;

      if (res.data.statusCode == "00000") {
        Message.success("sip拨打成功");
      } else if (res.data.statusCode != "00000") {
        Message.error(res.data.message);
      } else {
        Message.error(res.data.data);
      }
      this.config = res.data.data;
      // axios.post("http://118.190.211.245:9006/test/sip", params).then((res) => {
      //   this.config = res.data.data;
      //   //请求的数据存储在res.data 中
      //   console.log("res的值", res.data);
      // });
    },
    // 注册sip
    callPhone() {
      console.log("sip2");
      // debugger;
      // 拨打号码
      // this.getSipInfo()
      JsSIP.C.SESSION_EXPIRES = 120;
      JsSIP.C.MIN_SESSION_EXPIRES = 120;
      let obj = {
        sip: this.config.sip,
        wsip: this.config.wssip,
        username: this.config.ws_user,
        password: this.config.ws_pass,
        uri: `sip:${this.config.ws_user}@${this.config.sip}`,
      };

      this.eventHandlers = {
        progress: function (e) {
          console.log("call is in progress");
        },
        failed: function (e) {
          console.log("call failed: ", e);
        },
        ended: function (e) {
          console.log("call ended : ", e);
        },
        confirmed: function (e) {
          console.log("call confirmed");
        },
      };
      // 配置信息
      if (this.ua) {
        this.ua.stop();
      }
      this.socket = new JsSIP.WebSocketInterface(obj.wsip);
      // const socket = new this.JsSIP.WebSocketInterface(obj.wsip);
      const configuration = {
        sockets: [this.socket],
        uri: obj.uri,
        password: obj.password,
        register: false, //指示JsSIP用户代理是否应在启动时自动注册
        //utbound_proxy_set: obj.wsip,
        contact_uri:
          "sip:" +
          obj["username"] +
          "@" +
          obj["sip"] +
          ";transport=" +
          (obj["wsip"].substr(0, 3) == "wss" ? "wss" : "ws"),
      };
      console.log("configuration", configuration);
      // 创建UA
      let options = {
        eventHandlers: this.eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };

      this.ua = new JsSIP.UA(configuration);
      console.log("call号码", "sip:bob" + "18398754423" + "@" + obj["sip"]);
      // this.ua.call("sip:fs" + "17381586338" + "@" + obj["sip"], options);
      // 状态回调
      this.ua.on("connected", function (e) {
        console.log("--------已连接---------");
      });
      this.ua.on("disconnected", function (e) {
        console.log("-------未连接--------");
      });
      this.ua.on("registered", function (e) {
        console.log("--------已注册-------");
        // this.isRegister = true;
        // this.ua.unregister((options = null));
        this.registerState = true;
      });
      this.ua.on("unregistered", function (e) {
        console.log("------未注册--------");
        // this.isRegister = false;
        // this.ua.register();
        this.registerState = false;
      });
      this.ua.on("registrationFailed", (e) => {
        // this.$message.error("SIP注册失败,请联系管理员");
      });
      //客户接到了电话才会走这里
      this.ua.on("newRTCSession", (e) => {
        let audio = document.getElementById("audio");
        let session = e.session;
        // console.log("newRTCSession");
        // 闪亮登场的最终方式
        // const stream = new MediaStream();
        // const receivers = session.connection?.getReceivers();
        // if (receivers) receivers.forEach((receiver) => stream.addTrack(receiver.track));
        // audio.srcObject = stream;
        // 最后都要播放
        // audio.play();

        console.log("接到电话");
        // this.nextCallIntervalTimerEvent = e;
        let peerconnection = session.connection;
        if (e.originator === "local") {
          peerconnection.addEventListener("addstream", (event) => {
            try {
              audio.srcObject = event.stream;
              console.log("打电话");
            } catch (e) {
              // console.log(e);
            }
          });
          //    let audio = $('#audio')[0];
          // audio.srcObject=session.connection.getRemoteStreams()[0];
        } else {
          let callers = session.remote_identity.uri.user;
          //emitter.setCallinStatus.call(true, callers);
        }

        // 接听失败
        session.on("failed", (mdata) => {
          //emitter.setCallinStatus.call(false);
          // console.log("来电的时候 拒接或者 还没接听对方自己就挂断了");
        });

        // 接听成功
        session.on("accepted", (response, cause) => {
          // console.log("接听成功");
          //emitter.setCallinStatus.call(false);
        });

        // 接听成功后 挂断
        session.on("ended", () => {
          // console.log("接听结束");
        });

        // 通话被挂起
        session.on("hold", (data) => {
          let org = data.originator;
          if (org === "local") {
            // console.log("通话被本地挂起:", org);
          } else {
            // console.log("通话被远程挂起:", org);
          }
        });

        // 通话被继续
        session.on("unhold", (data) => {
          let org = data.originator;
          if (org === "local") {
            console.log("通话被本地继续:", org);
          } else {
            console.log("通话被远程继续:", org);
          }
        });
        // console.log(
        //   "Callid=" +
        //     e.request.headers["X-Callid"][0].raw +
        //     "------------------Phone=" +
        //     e.request.headers["X-Phone"][0].raw
        // );
        // const Taskid = e.request.headers["X-Taskid"][0].raw;
        // const Phone = e.request.headers["X-Phone"][0].raw;
        // const callId = e.request.headers["X-Callid"][0].raw;

        e.session.on("confirmed", (e) => {
          console.log("--------------------------电话接通，开始通话");
        });
        e.session.on("ended", (e) => {
          let audioB = document.getElementById("audioB");
          audioB.loop = true;
          audioB.play();
          setTimeout(() => {
            audioB.pause();
          }, 10000);
          console.log("---------------------------电话已挂断，开始问卷");
        });
        //绑定回调后先给坐席‘滴’一声
        // e.session.answer();
      });
      // 登陆
      this.ua.start();
      // 登出
      // ua.stop();
    },
  },
  // mod卸载前
  beforeDestroy() {
    let audio = document.getElementById("audio");
    audio.srcObject = {};
    audio.src = "";
  },
};
</script>

<style lang="less" scoped>
#sipCall {
  position: relative;
  z-index: 2016;

  header {
    h1 {
      font-weight: normal;
    }
  }

  main {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
}
</style>
