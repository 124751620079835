<template>
  <div class="layout">
    <div class="routerBox">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div class="bottomText">
      <p>联系我们：400-8096-985 15982099479</p>
      <p>四川商机通信息技术咨询有限公司</p>
      <p>Copyright ©2022 All rights reserved. 蜀ICP备2021005692号-2</p>
    </div>
  </div>
</template>

<script>


export default {
  // 注册
  components: {

  },
  data() {
    return {
    };
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  .bottomText{
    width: 100%;
    position: fixed;
    bottom: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  .routerBox {
    flex: 1;
  }
}
</style>