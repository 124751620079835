<template>
  <div class="left-menu" :class="{ w230: !isCollapse }">
    <!-- logo -->
    <div class="logo-container" @click="toHemo">
      <img class="logo" width="80" height="80" src="../../assets/images/logo2.png" v-if="!isCollapse" alt="logo" />
      <img class="logo" width="30" height="30" v-else src="../../assets/images/logo2.png" alt="logo" />
      <!-- <span v-if="isCollapse == false">商机通信</span> -->
      <!-- <div>
        商机
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" />
        通信
      </div> -->
    </div>

    <!-- 导航 -->
    <el-menu background-color="#fff" text-color="#333" :default-active="$route.path" router unique-opened
      :collapse="isCollapse" :collapse-transition="false">
      <!-- 只保持一个子菜单打开 unique-opened -->
      <!-- active-text-color="#3278FE" -->
      <!-- 后台首页 -->
      <el-menu-item index="/home">
        <i class="iconfont el-icon-s-home"></i>
        <span slot="title">系统首页</span>
      </el-menu-item>

      <!-- 坐席管理 -->
      <el-submenu index="0" v-if="(roleName != '人员' && roleName != '组长') ||
        parentId == 7 ||
        parentId == 3555
        ">
        <template slot="title">
          <i class="iconfont el-icon-s-platform"></i>
          <span>坐席管理</span>
        </template>
        <el-menu-item index="/creatCompany/creatCompany" v-if="roleName != '企业' && parentId != 7">公司管理</el-menu-item>
        <el-menu-item index="/creatCompany/companyQuestionTake"
          v-if="role == '客服' || roleName == '管理员'">公司问题记录</el-menu-item>
        <el-menu-item index="/creatCompany/creatCompanyService"
          v-if="parentId == 7 || parentId == 3555">公司管理--内部</el-menu-item>
        <el-menu-item index="/creatCompany/creatSeat" v-if="parentId != 7">坐席管理</el-menu-item>
        <el-menu-item index="/creatCompany/creatSeatService"
          v-if="parentId == 7 || parentId == 3555">坐席管理--内部</el-menu-item>
      </el-submenu>

      <!-- 组织架构 -->
      <el-submenu index="1" v-if="roleName == '企业'">
        <template slot="title">
          <i class="iconfont el-icon-s-check"></i>
          <span>组织架构</span>
        </template>
        <el-menu-item index="/structure/department">部门管理</el-menu-item>
        <el-menu-item index="/structure/authority">权限管理</el-menu-item>
      </el-submenu>

      <!-- 通话记录(已转移位置) -->
      <!-- <el-menu-item index="/callLog">
        <i class="iconfont el-icon-phone"></i>
        <span slot="title">通话记录</span>
      </el-menu-item> -->

      <!-- 财务管理 -->
      <el-submenu index="2" v-if="(roleName != '人员' && roleName != '组长') ||
        parentId == 3555 ||
        role == '客服'
        ">
        <template slot="title">
          <i class="iconfont el-icon-s-finance"></i>
          <span>财务管理</span>
        </template>
        <el-menu-item index="/finance/paylog"
          v-if="roleName == '管理员' || parentId == 3555 || role == '客服'">企业充值日志</el-menu-item>
        <el-menu-item index="/finance/changeLog">坐席日志信息</el-menu-item>
      </el-submenu>

      <!-- 客服管理 -->
      <el-submenu index="3" v-if="parentId == 3555 || roleName == '管理员' || role == '客服'">
        <template slot="title">
          <i class="iconfont el-icon-s-custom"></i>
          <span>客服管理</span>
        </template>
        <el-menu-item index="/service/pending">待处理续费查询</el-menu-item>
        <el-menu-item index="/service/processed">已处理续费查询</el-menu-item>
        <el-menu-item index="/service/practical" v-if="roleName == '管理员'">查询实际续费金额</el-menu-item>
        <el-menu-item index="/service/lastMonth" v-if="roleName == '管理员'">查询上月客服续费</el-menu-item>
      </el-submenu>

      <!-- 客户管理 -->
      <el-submenu index="4" v-if="realname != 'admin'">
        <template slot="title">
          <i class="iconfont el-icon-s-custom"></i>
          <span>客户管理</span>
        </template>
        <el-menu-item index="/client/encryptSwitch"
          v-if="realname != 'admin' && roleName != '组长' && roleName != '人员'">号码隐私开关</el-menu-item>

        <el-menu-item index="/client/clientNums">客户列表</el-menu-item>
        <el-menu-item index="/client/creatNums" v-if="realname != 'admin'">个人私海</el-menu-item>
        <el-menu-item index="/client/departNums" v-if="realname != 'admin'">部门公海</el-menu-item>
        <el-menu-item index="/client/companyNums" v-if="realname != 'admin'">公司公海</el-menu-item>
        <el-menu-item index="/client/evictionPolicy" v-if="roleName != '组长' && roleName != '人员'">回收策略</el-menu-item>
        <el-menu-item index="/client/numberApply" v-if="realname != 'admin' && roleName != '企业'">公海号码申请</el-menu-item>
        <el-menu-item index="/client/departmentNumberApply"
          v-if="realname != 'admin' && roleName != '企业'">部门海号码申请</el-menu-item>

        <el-menu-item index="/client/recycle"
          v-if="realname != 'admin' && roleName != '组长' && roleName != '人员'">回收站</el-menu-item>
      </el-submenu>

      <!-- 产品 -->
      <el-menu-item index="/product" v-if="realname != 'admin' && roleName != '人员'">
        <i class="iconfont el-icon-share"></i>
        <span slot="title">产品</span>
      </el-menu-item>

      <!-- 呼叫任务(已转移位置) -->
      <!-- <el-menu-item index="/callMission" v-if="realname != 'admin'">
        <i class="iconfont el-icon-s-flag"></i>
        <span slot="title">呼叫任务</span>
      </el-menu-item> -->

      <!-- 话务报表 -->
      <el-submenu index="5">
        <template slot="title">
          <i class="iconfont el-icon-s-order"></i>
          <span>话务报表</span>
        </template>
        <el-menu-item index="/callLog/callLog">通话记录</el-menu-item>
        <el-menu-item index="/callMission" v-if="realname != 'admin'">
          呼叫任务
        </el-menu-item>
        <el-menu-item index="/report/realReport" v-if="realname != 'admin'">实时报表</el-menu-item>
        <el-menu-item index="/rate/rate">语音转文字设置</el-menu-item>
        <el-menu-item index="/rate/rateLog" v-if="roleName == '企业'">号码检测扣费</el-menu-item>
        <el-menu-item index="/report/dayReport"
          v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'">日报表</el-menu-item>
        <el-menu-item index="/report/monthReport"
          v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'">月报表</el-menu-item>
      </el-submenu>

      <!-- 短信管理 -->
      <el-submenu index="7">
        <template slot="title">
          <i class="iconfont el-icon-s-comment"></i>
          <span>短信管理</span>
        </template>
        <el-menu-item index="/note/note" v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'">短信</el-menu-item>
        <el-menu-item index="/note/taskulous" v-if="realname != 'admin'">短信提醒跟进</el-menu-item>
        <el-menu-item index="/note/blackList"
          v-if="realname != 'admin' && roleName != '组长' && roleName != '人员'">短信人员黑名单</el-menu-item>
        <el-menu-item index="/note/massNoteAdd" v-if="realname != 'admin' && roleName != '企业'">群发短信模板添加</el-menu-item>

        <el-menu-item index="/note/hangNoteAdd" v-if="realname != 'admin' && roleName != '企业'">挂机短信模板添加</el-menu-item>
      </el-submenu>

      <!-- 号码检测 -->
      <el-submenu index="/number">
        <template slot="title">
          <i class="iconfont el-icon-notebook-2"></i>
          <span>号码检测</span>
        </template>
        <el-menu-item index="/number/NnumberSwitch" v-if="roleName == '企业'">号码检测开关</el-menu-item>
        <el-menu-item index="/number/NnumberDetection" v-if="realname != 'admin'">检测号码</el-menu-item>
        <el-menu-item index="/number/NnumberDetectionManage" v-if="realname == 'admin'">号码检测管理</el-menu-item>
      </el-submenu>

      <!-- 录音转文字 -->
      <!-- <el-submenu index="8">
        <template slot="title">
          <i class="iconfont el-icon-s-comment"></i>
          <span>录音转文字</span>
        </template>
        <el-menu-item index="/rate/rate">语音转文字设置</el-menu-item>
        <el-menu-item index="/rate/rateLog" v-if="roleName == '企业'"
          >查询扣费日志</el-menu-item
        >
      </el-submenu> -->

      <!-- 知识库 -->
      <el-menu-item index="/repository" v-if="realname != 'admin'">
        <i class="iconfont el-icon-s-management"></i>
        <span slot="title">知识库</span>
      </el-menu-item>

      <!-- 工单管理 -->
      <!-- <el-menu-item index="/workorder" v-if="realname != 'admin'">
        <i class="iconfont el-icon-s-promotion"></i>
        <span slot="title">工单管理</span>
      </el-menu-item> -->

      <el-submenu index="/workorder" v-if="realname != 'admin'">
        <template slot="title">
          <i class="iconfont el-icon-s-promotion"></i>
          <span>工单管理</span>
        </template>

        <el-menu-item index="/workorder">工单管理</el-menu-item>
        <el-menu-item index="/workorder/niche">商机管理</el-menu-item>
        <!-- <el-menu-item index="/skbHistory" v-if="roleName == '企业'" -->
        <el-menu-item index="/workorder/customOrder" v-if="roleName == '企业'">自定义审批流</el-menu-item>
      </el-submenu>

      <!-- 审核管理 -->
      <el-submenu index="9">
        <template slot="title">
          <i class="iconfont el-icon-s-claim"></i>
          <el-badge is-dot class="item" v-if="hintMessage">审核管理</el-badge>
          <span v-else>审核管理</span>
        </template>
        <el-menu-item index="/workorder/notCheckWorkorder">待审批工单</el-menu-item>
        <el-menu-item index="/audit/audit" v-if="realname != 'admin'">合同管理</el-menu-item>

        <el-menu-item index="/audit/collection" v-if="realname != 'admin'">回款管理</el-menu-item>
        <el-menu-item index="/client/applyAudit"
          v-if="realname != 'admin' && roleName != '人员' && roleName != '组长'">申请号码审核</el-menu-item>
        <el-menu-item index="/client/applyAudit1" v-if="roleName == '组长'">申请号码审核--组长</el-menu-item>
        <el-menu-item index="/note/massNoteAudit" v-if="(roleName != '企业' && roleName != '组长' && roleName != '人员') ||
          parentId == 3555 ||
          role == '客服'
          ">群发短信内容审核</el-menu-item>
        <el-menu-item index="/note/hangNoteAudit" v-if="(roleName != '企业' && roleName != '组长' && roleName != '人员') ||
          parentId == 3555 ||
          role == '客服'
          ">挂机短信内容审核</el-menu-item>
      </el-submenu>

      <!-- 推广 -->
      <el-menu-item index="/promotion" v-if="realname != 'admin'">
        <i class="iconfont el-icon-share"></i>
        <span slot="title">推广</span>
      </el-menu-item>

      <!-- 操作日志 -->
      <el-menu-item index="/log" v-if="realname == 'admin'">
        <i class="iconfont el-icon-document"></i>
        <span slot="title">操作日志</span>
      </el-menu-item>

      <!-- 取消标记 -->
      <el-menu-item index="/unmark">
        <i class="iconfont el-icon-warning"></i>
        <span slot="title">取消标记</span>
      </el-menu-item>

      <!-- 电子围栏 -->
      <el-menu-item index="/dzwl" v-if="roleName == '管理员'">
        <i class="iconfont el-icon-map-location"></i>
        <span slot="title">电子围栏</span>
      </el-menu-item>

      <!-- 搜客宝 -->
      <el-menu-item index="/skb" v-if="roleName != '管理员'">
        <i class="iconfont el-icon-s-unfold"></i>
        <span slot="title">拓客宝</span>
      </el-menu-item>

      <!-- 搜客宝管理 -->
      <!-- <el-menu-item index="/skbManage" v-if="roleName == '企业'">
        <i class="iconfont el-icon-s-custom"></i>
        <span slot="title">搜客宝管理</span>
        
      </el-menu-item> -->

      <el-submenu index="/skbManage" v-if="realname != 'admin' || roleName == '企业'">
        <template slot="title">
          <i class="iconfont el-icon-s-custom"></i>
          <span>拓客宝管理</span>
        </template>

        <el-menu-item index="/skbManage" v-if="roleName == '企业'">拓客宝管理列表</el-menu-item>
        <el-menu-item index="/skbHistory" v-if="roleName != '管理员'">挖掘线索历史记录</el-menu-item>
      </el-submenu>
      <!-- 挖掘线索历史记录 -->
      <!-- <el-menu-item index="/skbHistory" v-if="roleName != '管理员'">
        <i class="iconfont el-icon-s-data"></i>
        <span slot="title">挖掘线索历史记录</span>
      </el-menu-item> -->
    </el-menu>
    <!-- <el-submenu index="10" v-if="roleName == '企业'">
        <template slot="title">
          <i class="iconfont icon-data"></i>
          <span>搜客宝</span>
        </template>

        <el-menu-item index="/skb">挖掘数据</el-menu-item>
        <el-menu-item index="/skb/mapsearch">地图搜索</el-menu-item>
        <el-menu-item index="/skb/mysearch">我的搜索</el-menu-item>
      </el-submenu> -->
  </div>
</template>

<script>
import { workOrderRemindWorkType } from "@/api/workOrder";

export default {
  data() {
    return {
      isCollapse: false,
      role: null,
      roleName: null,
      realname: null,
      parentId: null,
      hintMessage: "",
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.parentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.role = localStorage.getItem("role");
  },
  mounted() {
    this.hintList();
  },
  methods: {
    toHemo() {
      this.$router.push('/');
    },

    //点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      if (this.isCollapse == true) {
        this.isCollapse = false;
      } else {
        this.isCollapse = true;
      }
    },

    // 待审核工单提示
    hintList() {
      let params = {};
      let res = workOrderRemindWorkType(params);
      res.then((res) => {
        this.hintMessage = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
// @keyframes turn {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   25% {
//     -webkit-transform: rotate(90deg);
//   }
//   50% {
//     -webkit-transform: rotate(180deg);
//   }
//   75% {
//     -webkit-transform: rotate(270deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }

.w230 {
  min-width: 230px;
}

.left-menu {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  // width: 230px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  .logo-container {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(191, 203, 217);

    .logo {
      cursor: pointer;
      margin-right: 5px;
      margin-bottom: 10px;
      animation: turn 0.03s linear infinite;
    }
  }

  .el-menu {
    &>.is-active>.el-submenu__title {

      .iconfont,
      span {
        color: #3278FE !important;
      }
    }

    border-right: 0px;
    color: red;

    .el-menu-item {
      color: #333;

      .iconfont {
        margin-right: 10px;
      }
    }

    .is-active {
      color: #fff !important;
      border-radius: 10px;
      background-color: #3278FE !important;
      background-size: 300px 100px;
    }

    .is-active:corner-present {
      color: red;
    }

    .el-submenu {
      .el-submenu__title {
        .iconfont {
          margin-right: 10px;
        }
      }

      .el-menu-item {
        &:hover {
          color: #fff !important;
          border-radius: 15px;
          background-color: #3278FE !important;
        }
      }
    }
  }

  /deep/.el-badge__content.is-fixed.is-dot {
    right: 0;
  }

  /deep/.el-badge__content.is-fixed {
    top: 20px;
  }
}
</style>
