/**
 * phone-encryption
 * 号码加密
 */

import request from '@/utils/request'

// 查询隐藏
export const findEncryption = (data) => {
    return request({
        method: 'post',
        url: '/phone-encryption/find-encryption',
        data
    })
}
// 
export const encryptionAdd = (data) => {
    return request({
        method: 'post',
        url: '/phone-encryption/add',
        data
    })
}
