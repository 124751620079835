<template>
  <div id="app">
    <!-- <vue-particles
      id="particles-js"
      class="lizi"
      color="#4AB4FF"
      :particleOpacity="0.7"
      :particlesNumber="150"
      shapeType="circle"
      :particleSize="2"
      linesColor="#4AB4FF"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="false"
      clickMode="push"
    >
    </vue-particles> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  //         ┌─┐       ┌─┐
  //      ┌──┘ ┴───────┘ ┴──┐
  //      │                 │
  //      │       ───       │
  //      │  ─┬┘       └┬─  │
  //      │                 │
  //      │       ─┴─       │
  //      │                 │
  //      └───┐         ┌───┘
  //          │         │
  //          │         │
  //          │         │
  //          │         └──────────────┐
  //          │                        │
  //          │                        ├─┐
  //          │                        ┌─┘
  //          │                        │
  //          └─┐  ┐  ┌───────┬──┐  ┌──┘
  //            │ ─┤ ─┤       │ ─┤ ─┤
  //            └──┴──┘       └──┴──┘
  //                神兽保佑
  //                代码无BUG!

  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    };
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.getInfo); //监听窗口改变
  },
  methods: {
    // 获取浏览器的缩放比例
    getZoom() {
      var ratio = 0;
      var screen = window.screen;
      var ua = navigator.userAgent.toLowerCase();

      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
      ) {
        ratio = window.outerWidth / window.innerWidth;
      }
      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      return (ratio / 100).toFixed(2);
    },

    // 窗口缩放控制
    getInfo() {
      let resize = this.getZoom();
      if (resize * 100 < 50) {
        // 默认打开页面时缩放为80%
        document.body.style.zoom = 0.8 / resize; //放大倍数，例如缩放的了25%，那么此时就会是1/0.25 =4,此时就是25的四倍状态也就是还原到了100%的状态，即校正
      }
    },
    // 移动端强制横屏
    init() {
      this.forceLandscapeScreenHandle();
      //   // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName("body")[0];
      const html = document.getElementsByTagName("html")[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },

    // 强制刷新--短暂白屏
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
  position: relative;
  font-size: 12px;

  // 全局灰色色调 (特殊情况使用用)
  //   -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: grayscale(100%);
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);

  /deep/ .el-table{
    .el-table__cell{
      padding: 8px 0;
    }
    th.el-table__cell{
      background-color: #F8F8F8;
    }
  } 

  #particles-js {
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 0.6;
  }

  .lizi {
    width: 12.5%;
    height: 100%;
  }
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media screen and (orientation: portrait) {

  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
</style>