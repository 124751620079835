/**
 * login
 * 登录
 */

import request from "@/utils/request";

// 登录
// 内部系统地址 login-no-ip
// 客户系统地址 /login/login3
export const getLogin = (data) => {
  return request({
    method: "post",
    url: "/login/login3",
    // url: "/login/login-no-ip",
    data,
  });
};

// 登录发送验证码
export const updateStatus = (data) => {
  return request({
    method: "post",
    url: "/seat-phone/save/update-status",
    data,
  });
};

// 验证账号是否被创建
export const findStatus = (data) => {
  return request({
    method: "post",
    url: "/seat-phone/save/find-status",
    data,
  });
};
// activePhone

export const getLoginNoIp = (data) => {
  return request({
    method: "post",
    // url: "/login/login3",
    url: "/login/login-no-ip",
    data,
  });
};

// 获取验证码
export const getCode = (data) => {
  return request({
    method: "post",
    url: "/login/get_code",
    data,
  });
};
// 登出
export const getLoginOut = (data) => {
  return request({
    method: "post",
    url: "/login/out",
    data,
  });
};

// 修改密码
export const updatePassword = (data) => {
  return request({
    method: "post",
    url: "/user/update",
    data,
  });
};
