<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <left-menu class="left" ref="LeftMenuRef" v-if="switchBool"></left-menu>
    <!-- 切换为旧版 -->
    <old-left-menu class="left" ref="oldLeftMenuRef" v-else></old-left-menu>

    <!-- 右侧主体内容 -->
    <div class="right-main">
      <right-header @sonBool="getBool" @toOld="getOldBool" v-if="switchBool"></right-header>
      <old-right-header @sonBool="getBool" @toOld="getOldBool" v-else></old-right-header>
      <!-- 历史标签 -->
      <!-- <v-tags></v-tags> -->
      <v-breadcrumb></v-breadcrumb>
      <div class="content">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import vTags from "@/components/Tags.vue";

// 引入组件
import LeftMenu from "./LeftMenu.vue";
import OldLeftMenu from "./OldLeftMenu.vue";
import vBreadcrumb from "./Breadcrumb.vue";
import RightHeader from "./RightHeader.vue";
import OldRightHeader from "./OldRightHeader.vue";

export default {
  // 注册
  components: {
    vTags,
    OldLeftMenu,
    vBreadcrumb,
    LeftMenu,
    RightHeader,
    OldRightHeader
  },
  data() {
    return {
      switchBool: true
    };
  },
  methods: {
    getBool(bool) {
      this.$refs.LeftMenuRef.toggleCollapse();
    }, getOldBool(bool) {
      this.switchBool = bool
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  height: 100%;

  .left {
    overflow: auto;
    // width: 230px !important;
  }

  .right-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f0f2f5;
    width: 0;

    // overflow: hidden;
    // height: 100%;
    .content {
      padding: 10px;
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}
</style>