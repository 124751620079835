import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import Login from "@/views/login/Login.vue"; // 登录页
import miniGame from "@/views/miniGame/index.vue"; // 小游戏页
import Layout from "@/views/layout/Layout.vue";
import LayoutOld from "@/views/layout/LayoutOld.vue";

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/login",
    component: Login,
  },
  //放松小游戏
  {
    path: "/miniGame",
    component: miniGame,
    redirect: "/miniGame/MBTI",
    meta: {
      path: "/miniGame",
      title: "性格小测试",
    },
    children: [
      {
        path: "/miniGame/MBTI",
        powerArr: [],
        internal: false,
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/miniGame/MBTI",
          title: "性格小测试",
        },
        component: () => import("@/views/miniGame/MBTI.vue"),
      },
    ],
  },
  // 后台首页
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    meta: {
      path: "/home",
      title: "首页",
    },
    children: [
      {
        path: "/home",
        powerArr: [],
        internal: false,
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/home",
          title: "系统首页",
        },
        component: () => import("@/views/home/Home.vue"),
      },
    ],
  },
  // 坐席管理
  {
    path: "/creatCompany",
    component: Layout,
    redirect: "/creatCompany/creat-company",
    meta: {
      path: "/creatCompany",
      title: "坐席管理",
    },
    children: [
      {
        path: "/creatCompany/creatCompany",
        component: () => import("@/views/creatCompany/creatCompany.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/creatCompany/creatCompany",
          title: "公司管理",
        },
      },
      {
        path: "/creatCompany/creatSeat",
        component: () => import("@/views/creatCompany/creatSeat.vue"),
        meta: {
          path: "/creatCompany/creatSeat",
          powerArr: ["企业", "管理员"],
          internal: false,
          title: "坐席管理",
        },
      },
      {
        path: "/creatCompany/companyQuestionTake",
        component: () => import("@/views/creatCompany/companyQuestionTake.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/creatCompany/companyQuestionTake",
          title: "公司问题记录",
        },
      },
      {
        path: "/creatCompany/creatCompanyService",
        component: () => import("@/views/creatCompany/creatCompanyService.vue"),
        meta: {
          powerArr: ["管理员", "人员", "组长", "企业"],
          internal: true,
          path: "/creatCompany/creatCompanyService",
          title: "公司管理--内部",
        },
      },
      {
        path: "/creatCompany/creatSeatService",
        component: () => import("@/views/creatCompany/creatSeatService.vue"),
        meta: {
          powerArr: ["管理员", "人员", "组长", "企业"],
          internal: true,
          path: "/creatCompany/creatSeatService",
          title: "坐席管理--内部",
        },
      },
    ],
  },
  // 组织架构
  {
    path: "/structure",
    component: Layout,
    redirect: "/structure/department",
    meta: {
      path: "/structure",
      title: "组织架构",
    },
    children: [
      {
        path: "/structure/department",
        component: () => import("@/views/structure/department.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/structure/department",
          title: "部门管理",
        },
      },
      {
        path: "/structure/authority",
        component: () => import("@/views/structure/authority.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/structure/authority",
          title: "权限管理",
        },
      },
    ],
  },
  // 通话记录
  // {
  //   path: '/callLog',
  //   component: Layout,
  //   meta: {
  //     path: "/callLog",
  //     title: "通话记录"
  //   },
  //   children: [{
  //     meta: {
  //       path: "/callLog",
  //       title: "通话记录"
  //     },
  //     path: '',
  //     component: () => import('@/views/callLog/callLog.vue')
  //   },]
  // },
  // 财务管理
  {
    path: "/finance",
    component: Layout,
    redirect: "/finance/paylog",
    meta: {
      path: "/finance",
      title: "财务管理",
    },
    children: [
      {
        path: "/finance/paylog",
        component: () => import("@/views/finance/paylog.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/finance/paylog",
          title: "企业充值日志",
        },
      },
      {
        path: "/finance/changeLog",
        component: () => import("@/views/finance/changeLog.vue"),
        meta: {
          powerArr: ["管理员", "企业"],
          internal: false,
          path: "/finance/changeLog",
          title: "坐席日志信息",
        },
      },
    ],
  },
  // 客服管理
  {
    path: "/service",
    component: Layout,
    redirect: "/service/pending",
    meta: {
      path: "/service",
      title: "客服管理",
    },
    children: [
      {
        path: "/service/pending",
        component: () => import("@/views/service/pending.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/service/pending",
          title: "待处理续费查询",
        },
      },
      {
        path: "/service/processed",
        component: () => import("@/views/service/processed.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/service/processed",
          title: "已处理续费查询",
        },
      },
      {
        path: "/service/practical",
        component: () => import("@/views/service/practical.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/service/practical",
          title: "查询实际续费金额",
        },
      },
      {
        path: "/service/lastMonth",
        component: () => import("@/views/service/lastMonth.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/service/lastMonth",
          title: "查询上月客服续费",
        },
      },
    ],
  },
  // 客户管理
  {
    path: "/client",
    component: Layout,
    redirect: "/client/monthReport",
    meta: {
      path: "/client",
      title: "客户管理",
    },
    children: [
      {
        path: "/client/encryptSwitch",
        component: () => import("@/views/client/encryptSwitch.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/client/encryptSwitch",
          title: "号码隐私开关",
        },
      },
      {
        path: "/client/clientNums",
        component: () => import("@/views/client/clientNums.vue"),
        meta: {
          powerArr: ["人员", "组长", "企业"],
          internal: false,
          path: "/client/clientNums",
          title: "客户列表",
        },
      },
      {
        path: "/client/creatNums",
        component: () => import("@/views/client/creatNums.vue"),
        meta: {
          powerArr: ["人员", "组长", "企业"],
          internal: false,
          path: "/client/creatNums",
          title: "个人私海",
        },
      },
      {
        path: "/client/departNums",
        component: () => import("@/views/client/departNums.vue"),
        meta: {
          powerArr: ["人员", "组长", "企业"],
          internal: false,
          path: "/client/departNums",
          title: "部门公海",
        },
      },
      {
        path: "/client/companyNums",
        component: () => import("@/views/client/companyNums.vue"),
        meta: {
          powerArr: ["人员", "组长", "企业"],
          internal: false,
          path: "/client/companyNums",
          title: "公司公海",
        },
      },
      {
        path: "/client/numberApply",
        component: () => import("@/views/client/numberApply.vue"),
        meta: {
          powerArr: ["人员", "组长"],
          internal: false,
          path: "/client/numberApply",
          title: "公海号码申请",
        },
      },
      {
        path: "/client/departmentNumberApply",
        component: () => import("@/views/client/departmentNumberApply.vue"),
        meta: {
          powerArr: ["人员", "组长"],
          internal: false,
          path: "/client/departmentNumberApply",
          title: "部门海号码申请",
        },
      },
      {
        path: "/client/evictionPolicy",
        component: () => import("@/views/client/evictionPolicy.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/client/evictionPolicy",
          title: "回收策略",
        },
      },
      {
        path: "/client/applyAudit",
        component: () => import("@/views/client/applyAudit.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/client/applyAudit",
          title: "申请号码审核",
        },
      },
      {
        path: "/client/applyAudit1",
        component: () => import("@/views/client/applyAudit1.vue"),
        meta: {
          powerArr: ["组长"],
          internal: false,
          path: "/client/applyAudit1",
          title: "申请号码审核--组长",
        },
      },
      {
        path: "/client/recycle",
        component: () => import("@/views/client/recycle.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/client/recycle",
          title: "回收站",
        },
      },
    ],
  },
  // 产品
  {
    path: "/product",
    component: Layout,
    meta: {
      path: "/product",
      title: "产品",
    },
    children: [
      {
        meta: {
          powerArr: ["企业", "组长"],
          internal: true,
          path: "/product",
          title: "产品",
        },
        path: "",
        component: () => import("@/views/product/product.vue"),
      },
    ],
  },
  // 呼叫任务
  // {
  //   path: '/callMission',
  //   component: Layout,
  //   meta: {
  //     path: "/callMission",
  //     title: "呼叫任务"
  //   },
  //   children: [{
  //     meta: {
  //       path: "/callMission",
  //       title: "呼叫任务"
  //     },
  //     path: '',
  //     component: () => import('@/views/callMission/callMission.vue')
  //   },]
  // },
  // 话务报表
  {
    path: "/report",
    component: Layout,
    redirect: "/report/realReport",
    meta: {
      path: "/report",
      title: "统计报表",
    },
    children: [
      {
        path: "/report/realReport",
        component: () => import("@/views/report/realReport.vue"),
        meta: {
          powerArr: ["企业", "组长", "人员"],
          internal: false,
          path: "/report/realReport",
          title: "实时报表",
        },
      },
      {
        path: "/report/corporateConsumption",
        component: () => import("@/views/report/corporateConsumption.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/report/corporateConsumption",
          title: "企业历史消费",
        },
      },
      {
        meta: {
          powerArr: ["企业", "组长", "人员"],
          internal: false,
          path: "/callMission",
          title: "呼叫任务",
        },
        path: "/callMission",
        component: () => import("@/views/callMission/callMission.vue"),
      },
      {
        path: "/report/dayReport",
        component: () => import("@/views/report/dayReport.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/report/dayReport",
          title: "日报表",
        },
      },
      {
        path: "/report/monthReport",
        component: () => import("@/views/report/monthReport.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/report/monthReport",
          title: "月报表",
        },
      },
      {
        meta: {
          powerArr: ["企业", "组长", "人员", "管理员"],
          internal: false,
          path: "/callLog/callLog",
          title: "通话记录",
        },
        path: "/callLog/callLog",
        component: () => import("@/views/callLog/callLog.vue"),
      },
    ],
  },
  // 统计视图
  // {
  //   path: "/charts",
  //   component: Layout,
  //   redirect: "/charts/clientsCharts",
  //   meta: {
  //     path: "/charts",
  //     title: "统计视图",
  //   },
  //   children: [
  //     {
  //       path: "/charts/clientsCharts",
  //       component: () => import("@/views/charts/clientsCharts.vue"),
  //       meta: {
  //         path: "/charts/clientsCharts",
  //         title: "意向客户数据报表",
  //       },
  //     },
  //     {
  //       path: "/charts/clientsRanking",
  //       component: () => import("@/views/charts/clientsRanking.vue"),
  //       meta: {
  //         path: "/charts/clientsRanking",
  //         title: "意向客户排名",
  //       },
  //     },
  //     {
  //       path: "/charts/callRanking",
  //       component: () => import("@/views/charts/callRanking.vue"),
  //       meta: {
  //         path: "/charts/callRanking",
  //         title: "通话时长排名",
  //       },
  //     },
  //     {
  //       path: "/charts/performanceRanking",
  //       component: () => import("@/views/charts/performanceRanking.vue"),
  //       meta: {
  //         path: "/charts/performanceRanking",
  //         title: "业绩排名",
  //       },
  //     },
  //   ],
  // },
  // 短信管理
  // {
  //   path: "/note",
  //   component: Layout,
  //   redirect: "/note/note",
  //   meta: {
  //     path: "/note",
  //     title: "短信管理",
  //   },
  //   children: [
  //     {
  //       path: "/note/note",
  //       component: () => import("@/views/note/note.vue"),
  //       meta: {
  //         path: "/note/note",
  //         title: "短信",
  //       },
  //     },
  //     {
  //       path: "/note/taskulous",
  //       component: () => import("@/views/note/taskulous.vue"),
  //       meta: {
  //         path: "/note/taskulous",
  //         title: "短信提醒跟进",
  //       },
  //     },
  //     {
  //       path: "/note/blackList",
  //       component: () => import("@/views/note/blackList.vue"),
  //       meta: {
  //         path: "/note/blackList",
  //         title: "短信人员黑名单",
  //       },
  //     },
  //     // {
  //     //   path: '/note/note',
  //     //   component: () => import('@/views/note/note.vue'),
  //     //   meta: { path: "/note/note", title: "短信" },
  //     // },
  //     {
  //       path: "/note/massNoteAdd",
  //       component: () => import("@/views/note/massNoteAdd.vue"),
  //       meta: {
  //         path: "/note/massNoteAdd",
  //         title: "群发短信模板添加",
  //       },
  //     },
  //     {
  //       path: "/note/massNoteAudit",
  //       component: () => import("@/views/note/massNoteAudit.vue"),
  //       meta: {
  //         path: "/note/massNoteAudit",
  //         title: "群发短信内容审核",
  //       },
  //     },
  //     {
  //       path: "/note/hangNoteAdd",
  //       component: () => import("@/views/note/hangNoteAdd.vue"),
  //       meta: {
  //         path: "/note/hangNoteAdd",
  //         title: "挂机短信模板添加",
  //       },
  //     },
  //     {
  //       path: "/note/hangNoteAudit",
  //       component: () => import("@/views/note/hangNoteAudit.vue"),
  //       meta: {
  //         path: "/note/hangNoteAudit",
  //         title: "挂机短信内容审核",
  //       },
  //     },
  //   ],
  // },
  // 系统工具
  {
    path: "/number",
    component: Layout,
    redirect: "/number/NnumberDetection",
    meta: {
      path: "/number",
      title: "系统工具",
    },
    children: [
      {
        path: "/number/NnumberSwitch",
        component: () => import("@/views/number/NnumberSwitch.vue"),
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/number/NnumberSwitch",
          title: "号码检测开关",
        },
      },
      {
        path: "/number/NnumberDetection",
        component: () => import("@/views/number/NnumberDetection.vue"),
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/number/NnumberDetection",
          title: "检测号码",
        },
      },
      {
        path: "/number/NnumberDetectionManage",
        component: () => import("@/views/number/NnumberDetectionManage.vue"),
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/number/NnumberDetectionManage",
          title: "号码检测管理",
        },
      },
      {
        path: "/repository",
        component: () => import("@/views/repository/repository.vue"),
        meta: {
          powerArr: ["管理员", "企业", "人员", "组长"],
          internal: true,
          path: "/repository",
          title: "知识库",
        },
      },
    ],
  },
  // 知识库
  // {
  //   path: "/repository",
  //   component: Layout,
  //   meta: {
  //     path: "/repository",
  //     title: "知识库",
  //   },
  //   children: [
  //     {
  //       meta: {
  //         path: "/repository",
  //         title: "知识库",
  //       },
  //       path: "",
  //       component: () => import("@/views/repository/repository.vue"),
  //     },
  //   ],
  // },
  // 工单管理
  {
    path: "/workorder",
    component: Layout,
    meta: {
      path: "/workorder",
      title: "工单管理",
    },
    children: [
      {
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: true,
          path: "/workorder",
          title: "工单管理",
        },
        path: "",
        component: () => import("@/views/workorder/workorder.vue"),
      },
      {
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: true,
          path: "/workorder/niche",
          title: "商机管理",
        },
        path: "/workorder/niche",
        component: () => import("@/views/workorder/niche.vue"),
      },
      {
        path: "/workorder/customOrder",
        component: () => import("@/views/workorder/customOrder.vue"),
        meta: {
          powerArr: ["企业"],
          internal: true,
          path: "/workorder/customOrder",
          title: "自定义审批流",
        },
      },
    ],
  },
  // 数据审核
  {
    path: "/audit",
    component: Layout,
    redirect: "/audit/audit",
    meta: {
      path: "/audit",
      title: "数据审核",
    },
    children: [
      {
        path: "/audit/audit",
        component: () => import("@/views/audit/audit.vue"),
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: true,
          path: "/audit/audit",
          title: "合同管理",
        },
      },
      {
        path: "/audit/collection",
        component: () => import("@/views/audit/collection.vue"),
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: true,
          path: "/audit/collection",
          title: "回款管理",
        },
      },
      {
        path: "/workorder/notCheckWorkorder",
        component: () => import("@/views/workorder/notCheckWorkorder.vue"),
        meta: {
          powerArr: ["企业", "人员", "组长", "管理员"],
          internal: true,
          path: "/workorder/notCheckWorkorder",
          title: "待审批工单",
        },
      },
    ],
  },
  // sip
  {
    path: "/sip",
    component: Layout,
    meta: {
      path: "/sip",
      title: "SIP呼叫",
    },
    children: [
      {
        meta: {
          powerArr: ["人员", "组长"],
          internal: false,
          path: "/sip",
          title: "SIP呼叫",
        },
        path: "",
        component: () => import("@/views/sip/sip.vue"),
      },
    ],
  },
  // 操作日志
  {
    path: "/log",
    component: Layout,
    meta: {
      path: "/log",
      title: "操作日志",
    },
    children: [
      {
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/log",
          title: "操作日志",
        },
        path: "/",
        component: () => import("@/views/log/log.vue"),
      },
    ],
  },
  // 取消标记
  {
    path: "/unmark",
    component: Layout,
    meta: {
      path: "/unmark",
      title: "取消标记",
    },
    children: [
      {
        meta: {
          powerArr: ["企业", "人员", "组长", "管理员"],
          internal: false,
          path: "/unmark",
          title: "取消标记",
        },
        path: "/",
        component: () => import("@/views/unmark/unmark.vue"),
      },
    ],
  },
  // 拓客宝
  // {
  //   path: "/skb",
  //   component: Layout,
  //   meta: {
  //     path: "/skb",
  //     title: "拓客宝",
  //   },
  //   children: [
  //     {
  //       meta: {
  //         path: "/skb",
  //         title: "拓客宝",
  //       },
  //       path: "/",
  //       component: () => import("@/views/skb/skb.vue"),
  //     },
  //   ],
  // },
  // 电子围栏
  {
    path: "/dzwl",
    component: Layout,
    meta: {
      path: "/dzwl",
      title: "电子围栏",
    },
    children: [
      {
        meta: {
          powerArr: ["管理员"],
          internal: false,
          path: "/dzwl",
          title: "电子围栏",
        },
        path: "/",
        component: () => import("@/views/dzwl/dzwlManage.vue"),
      },
    ],
  },
  // 录音转文字
  // {
  //   path: "/rate",
  //   component: Layout,
  //   redirect: "/rate/rate",
  //   meta: {
  //     path: "/rate",
  //     title: "录音转文字设置",
  //   },
  //   children: [
  //     {
  //       path: "/rate/rate",
  //       component: () => import("@/views/rate/rate.vue"),
  //       meta: {
  //         path: "/rate/rate",
  //         title: "录音转文字设置",
  //       },
  //     },
  //     {
  //       path: "/rate/rateLog",
  //       component: () => import("@/views/rate/rateLog.vue"),
  //       meta: {
  //         path: "/rate/rateLog",
  //         title: "查询扣费日志",
  //       },
  //     },
  //   ],
  // },
  // 拓客宝管理
  {
    path: "/skbManage",
    component: Layout,
    meta: {
      path: "/skbManage",
      title: "推广获客",
    },
    children: [
      {
        meta: {
          powerArr: ["企业"],
          internal: false,
          path: "/skbManage",
          title: "拓客宝列表",
        },
        path: "/",
        component: () => import("@/views/skbManage/skbManage.vue"),
      },
      {
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/skb",
          title: "拓客宝",
        },
        path: "/skb",
        component: () => import("@/views/skb/skb.vue"),
      },
      {
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/promotion",
          title: "推广",
        },
        path: "/promotion",
        component: () => import("@/views/promotion/promotion.vue"),
      },
      // {
      //   path: "/promotion",
      //   component: Layout,
      //   meta: {
      //     path: "/promotion",
      //     title: "推广",
      //   },
      //   children: [
      //     {
      //       meta: {
      //         path: "/promotion",
      //         title: "推广",
      //       },
      //       path: "/",
      //       component: () => import("@/views/promotion/promotion.vue"),
      //     },
      //   ],
      // },
    ],
  },
  // 挖掘线索历史记录
  {
    path: "/skbHistory",
    component: Layout,
    meta: {
      path: "/skbHistory",
      title: "推广获客",
    },
    children: [
      {
        meta: {
          powerArr: ["企业", "人员", "组长"],
          internal: false,
          path: "/skbHistory",
          title: "挖掘线索历史记录",
        },
        path: "/",
        component: () => import("@/views/skbHistory/skbHistory.vue"),
      },
    ],
  },
  // 推广
  // {
  //   path: "/promotion",
  //   component: Layout,
  //   meta: {
  //     path: "/promotion",
  //     title: "推广",
  //   },
  //   children: [
  //     {
  //       meta: {
  //         path: "/promotion",
  //         title: "推广",
  //       },
  //       path: "/",
  //       component: () => import("@/views/promotion/promotion.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
