/**
 * seat-phone
 * 坐席
 */

import request from '@/utils/request'

// 展示
export const seatPhoneList = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/list',
        data
    })
}
// 展示--客服
export const seatPhoneList1 = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/list1',
        data
    })
}
// 添加
export const seatPhoneAdd = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/add',
        data
    })
}

// 创建组长账户
export const seatStopAdd = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/add-stop',
        data
    })
}
// 
export const seatPhoneImportSeat = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/import-seat',
        data
    })
}
// 验证
export const seatPhoneGetCode = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/get_code',
        data
    })
}
// 编辑
export const seatPhoneUpdate = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/update',
        data
    })
}
// 删除
export const seatPhoneDel = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/del',
        data
    })
}
// 充值套餐包
export const seatPhoneBuySeatMoney = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/buy-seatMoney',
        data
    })
}
