/**
 * user
 * 用户
 */

import request from '@/utils/request'

// 修改密码
export const userUpdatePaasword = (data) => {
    return request({
        method: 'post',
        url: '/user/update_paasword',
        data
    })
}
// 展示查找
export const userFindlist = (data) => {
    return request({
        method: 'post',
        url: '/user/findlist',
        data
    })
}
// 展示
export const userList = (data) => {
    return request({
        method: 'post',
        url: '/user/list',
        data
    })
}
// 展示1
export const userList1 = (data) => {
    return request({
        method: 'post',
        url: '/user/list1',
        data
    })
}
// 编辑
export const userUpdate = (data) => {
    return request({
        method: 'post',
        url: '/user/update',
        data
    })
}
// 锁定
export const locking = (data) => {
    return request({
        method: 'post',
        url: '/sysUserRemarke/add',
        data
    })
}
// 锁定日志
export const lockJournal = (data) => {
    return request({
        method: 'post',
        url: '/sysUserRemarke/select',
        data
    })
}
// 查询操作日志
export const operationLogJournal = (data) => {
    return request({
        method: 'post',
        url: '/operationLog/selectAll',
        data
    })
}
// 添加
export const userAdd = (data) => {
    return request({
        method: 'post',
        url: '/user/add',
        data
    })
}
// 
export const userGetUserLogin = (data) => {
    return request({
        method: 'post',
        url: '/user/getUserLogin',
        data
    })
}

export const userCountLogin = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone/seat-rate-count',
        data
    })
}
// 
export const userFindAppidbytype = (data) => {
    return request({
        method: 'post',
        url: '/user/find-appidbytype',
        data
    })
}
// 客服下的公司
export const userOperatorsFindlist = (data) => {
    return request({
        method: 'post',
        url: '/user/operators-findlist',
        data
    })
}