/**
 * callLog
 * 号码检测
 */

import request from '@/utils/request'

// 号码检测
export const find = (data) => {
    return request({
        method: 'post',
        url: '/phone-test/find',
        data
    })
}

//查询号码检测开通公司
// admin查询所有号码检测开通公司
// 公司查询自己的费率/
export const list = (data) => {
    return request({
        method: 'post',
        url: '/sys-user-phone-test/list',
        data
    })
}

// 公司查询自己的费率/
export const update = (data) => {
    return request({
        method: 'post',
        url: '/sys-user-phone-test/add-or-update',
        data
    })
}

// 公司查询自己的费率/
export const findMoney = (data) => {
    return request({
        method: 'post',
        url: '/phone-test/find-money',
        data
    })
}

// 三网权限
// 查询是否开通
export const findOne_limit = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone-limit/findOne',
        data
    })
}

// 添加修改
export const add_update_limit = (data) => {
    return request({
        method: 'post',
        url: '/seat-phone-limit/add_update',
        data
    })
}