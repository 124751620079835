<template>
  <div class="right-header">
    <div class="appUpdata">
      <el-button type="primary" plain style="margin-bottom: 20px" @click="downloadAdd">
        <p>A</p>
        <p>P</p>
        <p>P</p>
        <p>下</p>
        <p>载</p>
      </el-button>

      <el-button type="warning" plain style="margin-bottom: 20px" @click="onTktz" v-if="realname != 'admin'">
        <p>探</p>
        <p>客</p>
        <p>躺</p>
        <p>赚</p>
      </el-button>

      <el-button plain @click="toMBTI" v-if="parentId == 7">
        <p>性</p>
        <p>格</p>
        <p>小</p>
        <p>测</p>
        <p>试</p>
      </el-button>
    </div>
    <el-row>
      <!-- 左侧 -->
      <el-col class="left" :md="8" :sm="16" :xs="14">
        <div style="padding: 0 15px; display: flex; align-items: center">
          <i :class="[activeBool ? 'el-icon-s-fold' : 'el-icon-s-unfold']" style="font-size: 20px; cursor: pointer"
            @click="toggleMenu"></i>
          <div class="nabar-title" v-if="roleName == '人员' || roleName == '组长'" style="margin-left: 10px; font-size: 14px">
            {{ companyName }}
          </div>
          <!-- <div class="nabar-title" v-if="roleName == '企业'" style="margin-left: 10px;font-size: 14px;">
            {{ realname }}
          </div> -->
          <!-- <p class="company-name" v-if="roleName == '人员' || roleName == '组长'">
            公司名称：<span>{{ companyName }}</span>
          </p>
          <p class="company-name" v-if="roleName == '企业'">
            公司名称：<span>{{ realname }}</span>
          </p> -->
          <!-- &emsp;| &emsp; -->
          <!-- <div style="color: #666">账号权限：{{ roleName }}</div> -->
        </div>
      </el-col>

      <!-- 右侧 -->
      <el-col class="right" :md="16" :sm="8" :xs="10">
        <!-- 清除uuid -->
        <el-button size="mini" class="call" type="primary" v-if="realname == 'admin' || rolePosition == '客服'"
          @click="delUuidBtn">
          清除UUID</el-button>

        <!-- 清除uuid -->
        <!-- <el-button size="mini" class="call" type="primary" v-if="roleName == '人员' || roleName == '组长'"
          @click="editpwdVisible = true">
          修改密码</el-button> -->

        <!-- 拨号 -->
        <el-button size="mini" class="call" type="primary" icon="el-icon-phone"
          v-if="roleName != '企业' && roleName != '管理员'" @click="oneCallNum">拨打</el-button>

        <!-- SIP呼叫 -->
        <!-- <el-button size="mini" type="primary" icon="el-icon-phone-outline" v-if="roleName == '人员' || roleName == '组长'"
          @click="onSip" style="border: 0px">
          SIP呼叫
        </el-button> -->

        <!-- 下载最新版本APP -->
        <!-- <el-button size="mini" plain type="primary" icon="el-icon-download" @click="downloadAdd" style="border: 0px">
          商机云APP
        </el-button> -->

        <!-- 创建搜客宝账号 -->
        <el-button size="mini" @click="addSkbUser" v-if="roleName == '企业'" style="border: 0px" icon="el-icon-document-add"
          plain type="primary">
          创建拓客宝
        </el-button>
        <!-- 查看搜客宝套餐 -->
        <el-button size="mini" @click="drawer = true" icon="el-icon-document" v-if="roleName == '企业'" style="border: 0px"
          plain type="primary">
          查看拓客宝
        </el-button>

        <!-- 探客躺赚 -->
        <!-- <el-button size="mini" @click="onTktz" plain type="primary" icon="el-icon-trophy" v-if="realname != 'admin'"
          style="border: 0px">
          探客躺赚
        </el-button> -->

        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb>
          <el-breadcrumb-item
            v-for="v in breads"
            :key="v.title"
            :to="{ path: v.path }"
          >
            {{ v.title }}
          </el-breadcrumb-item>
        </el-breadcrumb> -->

        <!-- 功能搜索 -->
        <div style="margin: 0 10px; cursor: pointer" v-if="hiddenMenu()">
          <span style="
              color: #333;
              font-size: 12px;
              text-align: center;
              font-weight: bold;
            ">功能搜索:&emsp;</span>
          <el-select size="mini" v-model="value" clearable filterable placeholder="请输入功能名称" @change="toRouter(item)">
            <el-option v-for="(item, i) in routerOption" :key="i" :label="item.title" :value="item.path">
            </el-option>
          </el-select>
        </div>

        <!-- 待审核工单 -->
        <!-- <el-button size="mini" @click="onHint" style="border: 0px" v-if="hintMessage">
          <el-badge is-dot style="font-size: 12px">{{ hintMessageText }}的工单</el-badge>
        </el-button> -->
        <el-popover placement="bottom" width="300" trigger="click" @after-enter="oneQuery">
          <el-tabs v-model="activeName" stretch @tab-click="handleClick">
            <el-tab-pane label="提醒" name="remind" v-if="parentId == 7">
              <div class="onHintBox">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  " v-if="hintMessage">
                  <span>{{ hintMessageText }}的其它工单</span>
                  <el-link type="success" @click="onHint">去查看</el-link>
                </div>
                <div style="text-align: center" v-else>暂无提醒</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="公告" name="notice">
              <div class="onHintBox">
                <el-badge style="margin-top: 15px" :value="operationArr.length" :max="99">
                  <h4>公告记录列表</h4>
                </el-badge>
                <div v-if="operationArr.length != 0">
                  <div>
                    <!-- <div v-for="v in operationArr" :key="v.id"> -->
                    <p style="font-weight: bold; font-size: 13px">
                      [ <span>{{ operationArr[0].realname }}</span> ] :
                      <span>{{ operationArr[0].remarks }}</span>
                    </p>
                    <p style="text-align: right; font-size: 10px">
                      {{ operationArr[0].createTime }}
                    </p>
                  </div>
                </div>
                <div v-else style="text-align: center">暂无公告</div>
                <p v-if="operationArr.length > 1" style="
                    margin-top: 20px;
                    text-align: right;
                    font-size: 10px;
                    cursor: pointer;
                  " @click="operationVisible = true">
                  查看更多 > >
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>
          <!-- <el-badge slot="reference" :is-dot="hintMessage" style="margin-left: 10px;cursor: pointer;"> -->
          <el-badge slot="reference" :is-dot="hintMessage">
            <!-- <el-badge slot="reference" :is-dot="hintMessage || operationArr.length != 0"> -->
            <!-- v-if="parentId == 7" -->
            <i class="el-icon-message-solid" style="font-size: 20px; color: #666; cursor: pointer"></i>
          </el-badge>
        </el-popover>

        <!-- <i :class="fullscreen?'iconfont icon-zuidahua':'iconfont icon-zuidahua1'" @click="clickFullscreen"></i> -->
        <div style="margin-left: 10px; cursor: pointer">
          <i class="el-icon-full-screen" @click="fullScreen" style="font-size: 20px"></i>
        </div>

        <span class="seperator"></span>
        <!-- 下拉 -->
        <el-dropdown @command="handleCommand">
          <div>
            <div style="
                width: 140px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                cursor: pointer;
                font-weight: bold;
              ">
              您好，{{ realname }}
            </div>

            <div style="text-align: center; cursor: pointer">
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item
              command="sip"
              v-if="roleName == '人员' || roleName == '组长'"
              >SIP呼叫</el-dropdown-item
            > -->
            <el-dropdown-item command="loginPass" v-if="roleName != '企业' && parentId == 7">登录PASS</el-dropdown-item>
            <el-dropdown-item command="sysc-yg" v-if="roleName == '人员'">使用手册</el-dropdown-item>
            <el-dropdown-item command="sysc-zz" v-if="roleName == '组长'">使用手册</el-dropdown-item>
            <el-dropdown-item command="sysc-gs" v-if="roleName == '企业'">使用手册</el-dropdown-item>
            <el-dropdown-item command="updata-pwd"
              v-if="roleName == '人员' || roleName == '组长' || getParentId()">修改密码</el-dropdown-item>
            <!-- <el-dropdown-item command="sysc-zzjg" v-if="roleName == '企业'">组织架构</el-dropdown-item> -->
            <!-- <el-dropdown-item command="sysc-zxgl" v-if="(roleName != '人员' && roleName != '组长') ||
              parentId == 7 ||
              parentId == 3555
              ">坐席管理</el-dropdown-item> -->
            <el-dropdown-item command="sysc-zxrz" v-if="roleName == '管理员' ||
              parentId == 3555 ||
              rolePosition == '客服'
              ">坐席日志</el-dropdown-item>
            <!-- <el-dropdown-item command="htpz" v-if="roleName == '企业'">后台配置</el-dropdown-item> -->
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- 头像 -->
        <!-- <el-avatar :size="50" :src="avatarUrl"></el-avatar> -->
      </el-col>
    </el-row>

    <!-- app弹出框 -->
    <el-dialog title="下载最新版本APP" :visible.sync="appVisible" class="app" width="30%">
      <img src="@/assets/images/sjyapp.png" alt="" class="appUploadClass" />
      <div style="text-align: center">
        <p>扫描二维码到浏览器下载</p>
        <!-- <el-link type="primary" href="http://47.109.70.167:9005/dmtapp.apk">点击下载
          
        </el-link> -->
        <!-- <el-link type="primary" href="http://paas.cddmt.cn:9005/app/sjy.apk">点击下载
        </el-link> -->

        <p class="p-red">华为、苹果用户可以到应用市场下载更新</p>
      </div>
    </el-dialog>

    <!-- app弹出框 -->
    <el-dialog title="公告记录列表" :visible.sync="operationVisible" width="50%">
      <el-table :data="operationArr" border header-cell-class-name="table-header" height="500">
        <el-table-column prop="realname" label="操作人" align="center" width="100"></el-table-column>
        <el-table-column prop="createTime" label="记录时间" align="center" width="200"></el-table-column>
        <el-table-column prop="remarks" label="记录内容" align="center"></el-table-column>
      </el-table>
      <div style="text-align: right">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </el-dialog>

    <!-- 拨打按钮弹出框 -->
    <el-dialog title="请输入号码" :visible.sync="onecallVisible" width="30%" :fullscreen="callBool" @close="closeOnecall">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="请输入号码">
          <el-input v-model="callNum" @input="setPhone()" placeholder="请输入号码" @keyup.enter.native="onecall"></el-input>
        </el-form-item>
        <el-form-item label="号码状态" v-if="numberTest">
          <div :class="{
            green: numberStatus == '有效号码',
            red: numberStatus == '无效号码',
            weizhi: numberStatus == '未知',
          }">
            {{ numberStatus }}
          </div>
        </el-form-item>
        <el-form-item label="号码检测">
          <el-switch v-model="numberTest" active-color="#13ce66" inactive-color="#ff4949" active-text="开启"
            inactive-text="关闭">
          </el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="onecallVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="onecall" v-if="!callButton">拨 打</el-button>
          <el-button size="mini" type="primary" :loading="true" v-else>拨打中</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- sip弹出框 -->
    <el-dialog title="SIP呼叫" :visible.sync="sipCallVisible" width="30%" :before-close="handleClose" @close="hang_up">
      <sipCall :callPhoneNum="callNum" :callPhoneData="callData" @showValue="showMethod">
      </sipCall>
    </el-dialog>
    <!--  创建搜客宝账号弹出框 -->
    <el-dialog title="创建拓客宝账号" :visible.sync="seatVisible" width="30%" @close="closeSkb">
      <el-form ref="skbform" :rules="rules" :model="skbform" label-width="100px">
        <el-form-item label="选择套餐">
          <el-select v-model="skbType" filterable placeholder="请选择套餐">
            <el-option v-for="item in tcList" :key="item.type" :label="'日查 ' + item.viewContactNum + ' 条套餐'"
              :value="[item.type, item.money]">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择开通员工">
          <el-select v-model="oneUser" filterable placeholder="请选择员工">
            <el-option v-for="(item, index) in userOptions" :key="index" :label="item.realname"
              :value="[item.id, item.realname]">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="v.label" v-for="v in skbLabel" :key="v.id">
          <!-- <el-input
            v-model="skbform[v.modelName]"
            :placeholder="'请输入' + v.label"
          ></el-input> -->
          <div>{{ skbType ? skbType[1] : 0 }} 元</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="seatVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="saveSeat">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 搜客宝套餐抽屉 -->
    <el-drawer title="拓客宝套餐" :visible.sync="drawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <div class="skbContent">
          <div style="font-size: 18px; font-weight: 700; margin: 14px 0">
            套餐信息
          </div>
          <div class="tclist" v-for="(v, i) in tcList" :key="i">
            <div style="flex: 3">
              <span style="font-weight: bold">套餐{{ i + 1 }}</span> &emsp;
              {{ "日查 " + v.viewContactNum + " 条套餐" }}
            </div>
            <div style="flex: 2">
              <span style="font-weight: bold">价格</span>
              &emsp; {{ v.money }}
            </div>
            <div style="flex: 1">
              <!-- <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="点错了"
                @confirm="deleteCompanyTcM(v.id)"
                icon="el-icon-info"
                icon-color="red"
                :title="`确认要删除套餐${v.type}吗?`"
              >
                <el-link style="color: #3278FE" slot="reference">删除</el-link>
              </el-popconfirm> -->
              <!-- <el-link
                style="margin-left: 10px; color: #3278FE"
                @click="editTcNameM(v)"
                >修改</el-link
              > -->
            </div>
          </div>
          <div style="margin-left: 3px" v-if="tcList.length == 0">
            暂无套餐信息
          </div>
        </div>
        <!-- <el-button
size="mini"           style="margin-top: 20px; float: right"
          type="primary"
          @click="newTc = true"
          >新增套餐<i class="el-icon-circle-plus-outline el-icon--right"></i
        ></el-button> -->
      </div>
    </el-drawer>

    <!--  修改套餐名 -->
    <el-dialog title="修改套餐名" :visible.sync="editTcName" width="30%" @close="tcName = null">
      <el-form label-width="100px">
        <el-form-item label="套餐名">
          <div>
            {{ currentForm ? currentForm.type : "" }}
          </div>
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="金额">
          <el-input v-model="tcName" :placeholder="'请输入新的套餐金额'"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="editTcName = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="updateCompanyTcM">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!--  新增套餐 -->
    <el-dialog title="创建套餐" :visible.sync="newTc" width="30%" @close="(tcform = {}), (skbType = null)">
      <el-form ref="tcform" :model="tcform" label-width="100px">
        <el-form-item label="套餐">
          <el-radio-group v-model="unit">
            <el-radio :label="1">按日开通</el-radio>
            <el-radio :label="2">按月开通</el-radio>
            <el-radio :label="3">按年开通</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="套餐类型">
          <el-select v-model="skbType" filterable placeholder="请选择套餐">
            <el-option v-for="item in options" :key="item.type" :label="item.type" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="v.label" v-for="v in tcLabel" :prop="v.modelName" :key="v.id">
          <el-input v-model="tcform[v.modelName]" :placeholder="'请输入' + v.label"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="newTc = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="addCompanyTcM">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 清除uuid弹出框 -->
    <el-dialog title="清除UUID" :visible.sync="delUuidVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="请输入号码">
          <el-input v-model="uuidNum" placeholder="请输入号码"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="delUuidVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="delUuidSubmit">清 除</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改密码弹出框 -->
    <el-dialog title="修改密码" :visible.sync="editpwdVisible" width="30%">
      <el-form ref="pwdForm" :model="pwdForm" label-width="100px" :rules="pwdRules">
        <el-form-item label="旧密码" prop="old_password">
          <el-input v-model="pwdForm.old_password" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="pwdForm.password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="验证新密码" prop="secondary_password">
          <el-input v-model="pwdForm.secondary_password" placeholder="请再次输入密码"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="editpwdVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="editpwdSubmit" :loading="updataing">确认修改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <baidu-map :center="{ lng: 0, lat: 0 }" @ready="getBmap"></baidu-map> -->
  </div>
</template>

<script>
import jsonp from "jsonp";
import { _debounce, _throttle } from "@/utils/prudence";

import AMapLoader from "@amap/amap-jsapi-loader";
import { BMPGL } from "@/api/bmpgl.js";
import sipCall from "@/views/sip/sipCall.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
// 引入ajax函数
import { callAppBind } from "@/api/call";
import { userList1 } from "@/api/user";
import {
  queryMeal,
  createSkbUser,
  selectAccount,
  queryCompanyTc,
  deleteCompanyTc,
  addCompanyTc,
  updateCompanyTc,
} from "@/api/skb";
import { workOrderRemindWorkType, operationList } from "@/api/workOrder";
import local from "@/utils/local";
import { confirmUserOnline, selectDzwlUserApi } from "@/api/dzwl";
import { phoneFindUser } from "@/api/phone";
import { getLoginOut, updatePassword } from "@/api/login";
import { get } from "http";
import axios from "axios";
import { find, list } from "@/api/NnumberDetection";

export default {
  components: {
    sipCall,
  },

  data() {
    return {
      oneQueryBool: true,
      updataing: false,
      pwdForm: {},
      editpwdVisible: false,
      companyName: null,
      value: null,
      routerOption: [],
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      operationVisible: false,
      activeName: "notice",
      getUserBool: true,
      activeBool: true,
      callBool: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      callButton: false,
      numberStatus: "未开始检测",
      numberTest: false,
      rolePosition: null,
      uuidNum: null,
      delUuidVisible: false,
      appVisible: false,
      oneUser: null,
      userOptions: [],
      unit: 3,
      tcLabel: [
        {
          id: 0,
          label: "每日可查数量",
          modelName: "viewContactNum",
        },
        {
          id: 1,
          label: "线索池容量",
          modelName: "clueVolume",
        },
        {
          id: 2,
          label: "开通金额",
          modelName: "money",
        },
      ],
      operationArr: [],
      newTc: false,
      tcform: {},
      editTcName: false,
      tcName: "",
      tcList: [],
      // 搜客宝套餐抽屉
      drawer: false,
      direction: "rtl",
      allStaffArr: [],
      isNewSkb: true,
      skbType: null,
      options: [],
      currentForm: {},
      skbLabel: [
        {
          id: 0,
          label: "套餐金额",
          modelName: "money",
        },
      ],
      skbform: {
        money: "",
      },
      seatVisible: false,
      ua: "",
      callData: "", // 拨号后接口数据
      sipCallVisible: false,
      realname: null, // 用户名
      roleName: null,
      // 默认头像
      avatarUrl: "http://localhost:8080/img/shangjiyun.d50877f3.png",
      // 面包屑数组
      breads: [],
      tktz: "http://tktz.cddmt.cn/",
      sysc: "http://123.60.30.116:9005/khd.pdf",
      callNum: null,
      onecallVisible: false,
      hintMessage: null,
      hintMessageText: "",
      form: {},
      rules: {},
      pwdRules: {
        old_password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        secondary_password: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
      },
      parentId: null,
      loginId: null,
    };
  },
  created() {
    this.calcBreads(); // 进入页面或刷新 都需要计算面包屑

    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.companyName = localStorage.getItem("companyName");

    if (this.roleName == "企业") {
      this.parentId = localStorage.getItem("id");
    } else {
      this.parentId = localStorage.getItem("parentId");
    }
    this.loginId = JSON.parse(localStorage.getItem("id"));
    this.rolePosition = localStorage.getItem("role");
    this.$nextTick(() => {
      if (this.parentId == 7) {
        this.hintList();
      }
      // this.getOperationArr();
      this.getAllRouteMeta();
    });
  },
  watch: {
    "$route.path"() {
      this.calcBreads(); // 切换导航 路由地址变化 也要计算面包屑/
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getselectAccount();
    // this.getAllStaff();
    if (this.roleName == "企业") {
      this.queryMealInfo();
      this.getCompanyTc();
      this.setrules();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    oneQuery() {
      if (this.oneQueryBool) {
        this.oneQueryBool = false;
        this.getOperationArr();
      }
    },
    // 公告和提醒切换事件
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 固定企业可以自己修改自己密码
    getParentId() {
      let arr = ["2", "18205", "20303", "19211"];
      return arr.includes(this.parentId);
    },
    // 特定公司不要人员看到部分菜单
    hiddenMenu() {
      let arr = [15360, 14966, 18334, 19216];
      let parentId = localStorage.getItem("parentId") * 1;
      if (this.roleName != "企业" && arr.includes(parentId)) {
        return false;
      } else {
        return true;
      }
    },
    async editpwdSubmit() {
      if (this.pwdForm.password != this.pwdForm.secondary_password) {
        Message.warning("两次输入的密码不一致");
        return false;
      }
      this.updataing = true;
      let params = {
        id: localStorage.getItem("id") * 1,
        old_password: this.pwdForm.old_password,
        password: this.pwdForm.password,
        realname: localStorage.getItem("realname"),
        username: localStorage.getItem("ms_username"),
      };
      let res = await updatePassword(params);
      if (res.data.statusCode == "00000") {
        Message.success("密码修改成功，请重新登录账户");
        await getLoginOut({});
        local.clear(); // 清除本地数据
        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
      } else {
        this.updataing = false;
        Message.error(res.data.message);
      }
    },
    toRouter(item) {
      this.$router.push(this.value);
    },
    // 在组件中的某个方法中获取所有路由信息，并提取children的meta属性
    getAllRouteMeta() {
      const routes = this.$router.options.routes;
      const allMeta = [];

      routes.forEach((route) => {
        this.getRouteMeta(route, allMeta);
      });

      this.routerOption = allMeta;
      // console.log("功能搜索", allMeta);
      // 进一步处理提取后的meta信息
    },

    getRouteMeta(route, allMeta) {
      // if (route.children) {
      //   route.children.forEach(child => {
      //     if (child.meta) {
      //       console.log("每个子路由",child.meta);
      //       allMeta.push(child.meta);
      //     }
      //     this.getRouteMeta(child, allMeta);
      //   });
      // }
      if (route.children) {
        route.children.forEach((child) => {
          if (child.meta) {
            if (child.meta.internal) {
              // console.log("ip为7", child.meta);
              if (
                child.meta.powerArr.includes(this.roleName) &&
                this.parentId == 7
              ) {
                allMeta.push(child.meta);
              }
            } else {
              // console.log("ip不为7", child.meta);
              // console.log("this.roleName", this.roleName);

              if (child.meta.powerArr.includes(this.roleName)) {
                // console.log("保存到路由");
                allMeta.push(child.meta);
              }
            }
          }
          this.getRouteMeta(child, allMeta);
        });
      }
    },
    // if (route.children) {
    //     route.children.forEach(child => {
    //       if (child.meta) {
    //         if (child.meta.internal) {
    //           console.log("ip为7");
    //           if (child.meta.powerArr.includes(this.roleName) && this.parentId == 7) {
    //             allMeta.push(child.meta);
    //           }
    //         } else {
    //           console.log("ip不为7");

    //           if (child.meta.powerArr.includes(this.roleName)) {
    //             allMeta.push(child.meta);
    //           }
    //         }
    //       }
    //       this.getRouteMeta(child, allMeta);
    //     });
    //   }

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getOperationArr();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getOperationArr();
    },
    toggleMenu() {
      this.activeBool = !this.activeBool;
      this.$emit("sonBool", this.activeBool);
    },
    setPhone() {
      // console.log("一阶段", this.callNum);
      this.callNum = this.callNum.replace(/[^\d]/g, "");
      // console.log("二阶段", this.callNum);
      this.callNum = this.callNum.slice(0, 12);
      // console.log("三阶段", this.callNum);

      if (this.callNum.replace(/[^\d]/g) && this.callNum.length == 11) {
        this.disabled = false;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      if (this.windowWidth < 1000 || this.windowHeight < 200) {
        this.callBool = true;
      } else {
        this.callBool = false;
      }
    },
    fullScreen() {
      // documentElement 属性以一个元素对象返回一个文档的文档元素
      var el = document.documentElement;
      el.requestFullscreen ||
        el.mozRequestFullScreen ||
        el.webkitRequestFullscreen ||
        el.msRequestFullScreen
        ? el.requestFullscreen() ||
        el.mozRequestFullScreen() ||
        el.webkitRequestFullscreen() ||
        el.msRequestFullscreen()
        : null;
    },
    // 关闭弹框
    closeOnecall() {
      this.numberStatus = "未开始检测";
    },
    // 清除uuid
    delUuidBtn() {
      this.delUuidVisible = true;
    },
    delUuidSubmit() {
      let params = {
        activePhone: this.uuidNum,
      };
      axios
        .post("/seat-phone/del-onlyId", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch((err) => { });
    },
    // app下载
    downloadAdd() {
      this.appVisible = true;
    },
    // 获取所员工信息
    async getUserList() {
      let res = await phoneFindUser();
      if (res.data.statusCode == "00000") {
        this.userOptions = res.data.data.user;
        this.userOptions.push({
          id: localStorage.getItem("id"),
          realname: localStorage.getItem("realname"),
        });
      }
      // console.log("获取所员工信息", res.data);
    },
    editTcNameM(v) {
      this.currentForm = v;
      this.editTcName = true;
    },
    // 修改公司套餐金额
    async updateCompanyTcM() {
      let params = {
        id: this.currentForm.id,
        money: this.tcName,
      };
      let res = await updateCompanyTc(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.getCompanyTc();
      this.editTcName = false;
    },
    // 新增公司套餐金额
    async addCompanyTcM() {
      let params = {
        companyId: localStorage.getItem("id"),
        companyName: this.realname,
        unit: this.unit,
        ...this.tcform,
      };
      let res = await addCompanyTc(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.getCompanyTc();
      this.newTc = false;
    },
    // 删除公司套餐金额
    async deleteCompanyTcM(id) {
      let res = await deleteCompanyTc({
        id,
      });
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.getCompanyTc();
    },

    // 查信公司套餐金额设置
    async getCompanyTc() {
      let params = {
        page: "1",
        size: "100",
      };
      if (this.roleName == "管理员") {
        // params.companyName = ""
      }
      let res = await queryCompanyTc(params);
      if (res.data.data) {
        this.tcList = res.data.data;
      }
      // console.log("套餐111", res);
      // tcList
    },

    // 关闭搜客宝弹框
    closeSkb() {
      this.skbType = null;
      this.oneUser = null;
      this.skbform = {
        money: "",
      };
    },

    // 获取所有员工
    async getAllStaff() {
      let res = await userList1({});
      // console.log("获取所有员工", res);
      if (res.data.statusCode == "00000") {
        this.allStaffArr = res.data.data.map((v) => {
          return v.user_id;
        });
      }
    },
    // 表单验证
    setrules() {
      let arr = Object.keys(this.skbform);
      arr.forEach((v) => {
        this.rules[v] = [
          {
            required: true,
            message: "内容不能为空",
            trigger: "blur",
          },
        ];
      });
      // console.log("this.rrules", this.rules);
    },
    // 查询账号
    async getselectAccount() {
      // let res = await selectAccount({
      //   page: "1",
      //   size: "10",
      // });
      // console.log("当前公司uid'", res);
      // let data = res.data.data;
      // if(data.length == 0) this.isNewSkb = true
    },
    // 确认创建搜客宝
    async saveSeat() {
      let bool = false;
      this.$refs["skbform"].validate((valid) => {
        if (valid) {
          bool = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      if (bool) {
        if (!this.oneUser) {
          Message.warning("还未选择员工");
          return false;
        }

        if (!this.skbType) {
          Message.warning("套餐不能为空");
          return false;
        }
        let params = {
          id: localStorage.getItem("id"),
          skbUserId: this.oneUser[0],
          skbUserName: this.oneUser[1],
          createCompanyName: localStorage.getItem("realname"),
          meals: [
            {
              type: this.skbType[0],
            },
          ],
          money: this.skbType[1] * 1,
        };

        console.log("params", params);
        let res = await createSkbUser(params);
        console.log("查询创建信息", res);

        if (res.data.statusCode == "50000") {
          Message.error(res.data.data);
        } else {
          Message.success(res.data.message);
          this.seatVisible = false;
        }
      }
    },
    // 打开创建搜客宝
    addSkbUser() {
      if (this.getUserBool) {
        this.getUserList();
        this.queryMealInfo();
        this.getUserBool = false;
      }
      this.seatVisible = true;

      // 测试第三方接口
      // let key = "7c8c7c0bba684eb932ac56ed88c8e989";

      // jsonp(
      //   `http://apis.juhe.cn/fapig/character_test/questions?level=&key=${key}`,
      //   null,
      //   (err, data) => {
      //     if (err) {
      //       console.error('err',err);
      //     } else {
      //       console.log('data',data);
      //     }
      //   }
      // );

    },
    // 查询套餐信息
    async queryMealInfo() {
      let params = {};
      let res = await queryMeal(params);
      if (res.data.statusCode == "00000") {
        // console.log("查询套餐信息", res.data);
        this.options = res.data.data.meals;
      }
    },
    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => { });
    },
    onSip() {
      this.$router.push("/sip");
    },
    toMBTI(){
      this.$router.push("/miniGame/MBTI");
    },
    onTktz() {
      window.open(this.tktz, "_blank");
    },
    async hintList() {
      // 待审核工单提示
      let params = {};
      let res = await workOrderRemindWorkType(params);
      this.hintMessage = res.data.data;
      this.hintMessageText = res.data.message;
    },
    async getOperationArr() {
      // 公告
      let params = {
        page: this.page, //第几页
        size: this.size, //每页的数量
      };
      if (this.roleName == "企业") {
        params.parentId = this.loginId;
      } else {
        params.userId = this.loginId;
      }
      // params.parentId = localStorage.getItem("id");

      let res1 = await operationList(params);
      this.pageIndex = res1.data.currentPage;
      this.size = res1.data.size;
      this.pageTotal = res1.data.total;
      this.operationArr = res1.data.data;
    },
    // 跳转到待审核工单
    onHint() {
      this.$router.push("/workorder/notCheckWorkorder");
    },
    // getBmap({ BMap, map }) {
    //   // console.log("地图定位");
    //   let _this = this;
    //   this.$nextTick(() => {
    //     var geolocation = new BMap.Geolocation({
    //       enableHighAccuracy: true, //是否使用高精度定位，默认:true
    //     });
    //     geolocation.getCurrentPosition(function (r) {
    //       console.log("rrrrrrrrrrr", r);
    //     });
    //   });
    // },
    //自动定位获取当前信息
    getlocation() {
      let _this = this;
      this.$nextTick(() => {
        var geolocation = new BMap.Geolocation({
          // enableHighAccuracy: true, //是否使用高精度定位，默认:true
          // timeout: 10000, //超过10秒后停止定位，默认：无穷大
          // maximumAge: 0, //定位结果缓存0毫秒，默认：0
          // convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          // showButton: true, //显示定位按钮，默认：true
          // buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          // buttonOffset: new BMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          // showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          // showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          // panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          // zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        geolocation.getCurrentPosition(
          function (r) {
            console.log("rrrrrrrrrrr", r);
            if (r === null) {
              // _this.getlocation()
            }
            // if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            //   mk = new BMap.Marker(r.point);
            //   getAddress(r.point);
            // } else {
            //   alert("failed" + this.getStatus());
            // }
          }, //启用高精度
          {
            enableHighAccuracy: true,
          }
        );
      });
      // AMapLoader.load({
      //   key: "d3ef7ad351416f1e203a88e69180e014",
      //   version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //   plugins: ["AMap.Geolocation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      // })
      //   .then((AMap) => {
      //     console.log("自动定位获取当前信息");
      //     AMap.plugin("AMap.Geolocation", function () {
      //       var geolocation = new AMap.Geolocation({
      //         convert:true,
      //         useNative:true,
      //         // 是否使用高精度定位，默认：true
      //         enableHighAccuracy: true,
      //         // 设置定位超时时间，默认：无穷大
      //         timeout: 5000,
      //         //  定位按钮的排放位置,  RB表示右下
      //         position: "RB",
      //         GeoLocationFirst:true,
      //         noIpLocate: 0, //是否禁用IP精确定位，默认为0，0:都用 1:手机上不用 2:PC上不用 3:都不用
      //         noGeoLocation: 0, // 是否禁用浏览器原生定位，默认为0，0:都用 1:手机上不用 2:PC上不用 3:都不用
      //         getCityWhenFail: false, // 定位失败之后是否返回基本城市定位信息
      //         needAddress: true, //是否需要将定位结果进行逆地理编码操作
      //         extensions: "all", //是否需要详细的逆地理编码信息，默认为'base'只返回基本信息，可选'all'
      //       });

      //       geolocation.getCurrentPosition(function (status, result) { //获取用户当前的精确位置
      //         console.log("定位结果",result);
      //         if (status == "complete") {
      //           onComplete(result);
      //         } else {
      //           onError(result);
      //         }
      //       });

      //       function onComplete(data) {
      //         // data是具体的定位信息
      //         console.log("data是具体的定位信息",data);
      //       }

      //       function onError(data) {
      //         // 定位出错
      //         console.log("定位出错");
      //       }
      //     });
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
    // 拨打按钮
    async oneCallNum() {
      this.getlocation();
      // //根据用户IP 返回城市级别的定位结果
      // let native = new BMap.LocalCity();
      // native.get(async (res) => {
      //   let params = {
      //     companyId: localStorage.getItem("parentId"),
      //     userId: localStorage.getItem("id"),
      //   };
      //   // 确认在不在白名单
      //   let res1 = await selectDzwlUserApi(params);
      //   let data1 = res1.data.data;
      //   if (data1.length != 0) {
      this.callNum = "";
      this.onecallVisible = true;
      //   } else {
      //     // 确定用户在不在经纬度接口
      //     params = {
      //       userLongitude: res.center.lng, //围栏圆心经度
      //       userLatitude: res.center.lat, ////围栏圆心纬度
      //       companyId: localStorage.getItem("parentId"), // 公司id
      //       userId: localStorage.getItem("id"), // 用户id
      //     };
      //     let res2 = await confirmUserOnline(params);
      //     if (res2.data.statusCode == "00000") {
      //       this.callNum = "";
      //       this.onecallVisible = true;
      //     } else {
      //       Message.warning("你当前的位置不在公司围栏内!");
      //       return false;
      //     }
      //   }
      // });
    },
    // 回车触发拨号
    // keyupSubmit() {
    //   if (this.onecallVisible == true) {
    //     document.onkeydown = (e) => {
    //       const _key = window.event.keyCode;
    //       if (_key === 13) {
    //         this.onecall(); //回车想调用的方法
    //       }
    //     };
    //   }
    // },
    // 确认拨打
    yesCall() {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let activePhone = JSON.parse(localStorage.getItem("ms_username"));
      let params = {
        passivePhone: this.callNum,
        activePhone,
        parentId,
      };
      let res = callAppBind(params);
      res.then((res) => {
        this.callData = res;
        console.log(" this.callData", this.callData);
        if (res.data.statusCode == "00000") {
          Message.success({
            message: res.data,
          });
          // console.log(1234567, res.data.data.sip);
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            // console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          let data = res.data.data;
          if (data) {
            // this.$message.error(JSON.parse(data).message);
            Message.success({
              message: JSON.parse(data).message,
            });
            // this.$message.error((JSON.parse(data).message) || (JSON.parse(data).msg));
          } else {
            Message.success({
              message: res.data,
            });
          }
        }
      });
    },
    // 拨打确定按钮
    async onecall() {
      this.callButton = true;
      if (this.numberTest) {
        // 开启号码检测时
        let params = {
          phone: this.callNum + "",
        };
        let res = await find(params);
        if (res.data.statusCode == "00000") {
          let status = res.data.data[0].status;
          if (status == 1) {
            this.numberStatus = "有效号码";
            await this.yesCall();
          } else if (status == 2) {
            this.numberStatus = "无效号码";
          } else {
            this.numberStatus = "未知";
            await this.yesCall();
          }
        } else {
          this.numberTest = false;
          Message.error(res.data.message);
        }
      } else {
        // 关闭号码检测时
        await this.yesCall();
      }

      this.tiemID = setTimeout(() => {
        this.callButton = false;
      }, 3000);

      // this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.$message({
      //       type: "success",
      //       message: "删除成功!",
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },
    // 计算面包屑
    calcBreads() {
      let temp = [
        {
          path: "/",
          title: "首页",
        },
      ];

      // 计算出当前面包屑 循环匹配到的路由
      this.$route.matched.forEach((v) => {
        // 如果匹配到的路由meta中配置了path 和 title
        if (v.meta.path && v.meta.title) {
          // 就放在一个对象中，且 把对象push进入temp里面
          temp.push({
            path: v.meta.path,
            title: v.meta.title,
          });
        }
      });

      // 把结果赋值渲染
      this.breads = temp;
    },
    toLogin() {
      let url = `http://syshb.cddmt.cn/login?salesman=${this.realname}`;
      // let url = `http://localhost:80/audit?salesman=${this.realname}`
      window.open(url, "_blank");
      // window.location.href = url;
    },
    // 点击菜单项
    handleCommand(command) {
      // 使用手册
      if (command === "sysc-yg") {
        window.open("https://shimo.im/docs/m8AZVrYLv6CzDVAb/read", "_blank");
      }
      if (command === "sysc-zz") {
        window.open("https://shimo.im/docs/erAdPQgLJrUVmBAG/read", "_blank");
      }
      if (command === "sysc-gs") {
        window.open("https://shimo.im/docs/gXqmebOyNDCRLGqo/read", "_blank");
      }
      if (command === "updata-pwd") {
        this.editpwdVisible = true;
      }
      if (command === "sysc-zzjg") {
        this.$router.push("/structure/department");
      }
      if (command === "sysc-zxgl") {
        if (this.parentId != 7) {
          this.$router.push("/creatCompany/creatSeat");
        } else {
          this.$router.push("/creatCompany/creatSeatService");
        }
      }
      if (command === "sysc-zxrz") {
        this.$router.push("/finance/changeLog");
      }

      // 后台配置
      if (command === "htpz") {
        // this.$router.push("/login");
        this.$emit("toOld", false);
      }
      // 登录PASS
      if (command === "loginPass") {
        this.toLogin();
        // let url = `http://syshb.cddmt.cn/login?password=${id}&username=${name}`
        // let url = `http://syshb.cddmt.cn/login?password=123456&username=test-hb`
        // let url = `http://192.168.0.14/login?password=sjtxhb*syy&username=damaitong-hb`
        // let url = `http://syshb.cddmt.cn/login?password=sjtxhb*syy&username=damaitong-hb`

        // window.location.href = url;
        // window.open(url, "_blank");
        // window.open(url, "_self");
      }

      // 退出登录
      if (command === "logout") {
        let params = {};
        let res = getLoginOut(params);
        res.then((res) => {
          local.clear(); // 清除本地数据
          Message.success("退出成功");
          this.$router.push("/login");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.onHintBox {
  min-height: 160px;
  width: 100%;
}

.appUpdata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 8%;
  right: 3%;
  z-index: 9;

  .el-button {
    // width: 20px !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }
}

.green {
  color: #3278fe;
}

.red {
  color: #e63e31;
}

.weizhi {
  color: #ccc;
}

.appUploadClass {
  width: 50%;
  height: 50%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  margin-bottom: 10px;
}

.app {
  /deep/ .el-dialog__body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // padding: 0px 20px;
    box-sizing: border-box;
    align-items: center;

    >div {
      // padding: 30px 0;
      box-sizing: border-box;

      p {
        margin-top: 10px;
      }

      .p-red {
        font-weight: bold;
        color: red;
      }
    }
  }
}

* {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}

.right-header {
  padding: 0 20px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 0px 1px #ccc;

  .el-col {
    height: 60px;
    display: flex;
    align-items: center;

    .el-avatar {
      margin-left: 5px;
    }
  }

  .left {
    .seperator {
      width: 1px;
      height: 20px;
      background-color: #eaeaef;
      margin: 0 20px;
    }

    .el-button {
      color: #fff;
      background-color: #3278fe;
      border-radius: 30px !important;
      border: 1px solid #e5ebff !important;
    }

    .el-button--primary.is-plain:focus,
    .el-button--primary.is-plain:hover {
      // background: #3278FE;
      // border-color: #3278FE;
      opacity: 0.7;
    }

    .call {
      // height: 40px;
      width: 100px;
      // margin-left: 30px;
      // margin-right: 30px;
      background-image: linear-gradient(to right, #e0e0e0, #7ba6dc);
      border: 0;
      border-radius: 5px;
    }
  }

  .right {
    justify-content: flex-end;

    .seperator {
      width: 1px;
      height: 20px;
      background-color: #eaeaef;
      margin: 0 20px;
    }

    .el-button {
      color: #fff;
      background-color: #3278fe;
      border-radius: 30px !important;
      border: 1px solid #e5ebff !important;
    }

    .el-button--primary.is-plain:focus,
    .el-button--primary.is-plain:hover {
      // background: #3278FE;
      // border-color: #3278FE;
      opacity: 0.7;
    }

    .call {
      // height: 40px;
      width: 100px;
      // margin-left: 30px;
      // margin-right: 30px;
      background-image: linear-gradient(to right, #e0e0e0, #7ba6dc);
      border: 0;
      border-radius: 5px;
    }
  }
}

/deep/.el-drawer {
  width: 45% !important;

  .el-drawer__header {
    font-size: 18px;
  }

  .skbContent {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 10px 10px;
    box-sizing: border-box;

    .tclist {
      color: #606266;
      font-size: 16px;
      display: flex;
      padding-bottom: 14px;
      box-sizing: border-box;
    }
  }
}
</style>
