/**
 * ajax底层工具函数封装
 */

import axios from "axios";
import local from "@/utils/local";
import router from "@/router";
import { Message } from "element-ui";

// axios.defaults.baseURL = "http://192.168.1.105:9999/app"; // 发送ajax的服务器地址 // 测试
// axios.defaults.baseURL = "http://192.168.0.36:9999/vo"; // 发送ajax的服务器地址 // 测试
// axios.defaults.baseURL = 'https://sjtx.cddmt.cn:9999/app' // 发送ajax的服务器地址 // 正式
// axios.defaults.baseURL = 'https://app.cddmt.cn/app' // 发送ajax的服务器地址 // 被攻击了(换回)
axios.defaults.baseURL = "https://syss.cddmt.cn/api"; // 发送ajax的服务器地址 // 被攻击了(防护解析地址)   使用ing  4/26
// axios.defaults.baseURL = "http://syss.cddmt.cn:8000/api"; // 发送ajax的服务器地址 // 被攻击了(防护解析地址)   使用ing  1008666666666
// axios.defaults.baseURL = "http://192.168.1.126:9999/app"; // 发送ajax的服务器地址 // 被攻击了(防护解析地址)  测试用

// axios.defaults.baseURL = "http://124.70.206.131:9999/app"; // 发送ajax的服务器地址 // 被攻击了(防护解析地址)   使用ing

// axios.defaults.baseURL = "http://asd.cddmt.cn"; // 发送ajax的服务器地址 // 被攻击了(防护解析地址)   使用ing

// axios.defaults.baseURL = "https://sjtx.cddmt.cn/api"; // 发送ajax的服务器地址
// axios.defaults.baseURL = "http://cdsjt.cddmt.cn/api"; // 发送ajax的服务器地址
// axios.defaults.baseURL = "https://cssaas.cddmt.cn:9999/app"; // 内部地址

// axios.defaults.baseURL = "http://jiangfa.cddmt.cn/api"; // 发送ajax的服务器地址
// axios.defaults.baseURL = "http://testsjt.cddmt.cn/api"; // 测试地址

//       ====== 修改默认公共地址的同时要改登录的ip地址  ========

// axios.defaults.baseURL = 'https://saass.cddmt.cn/app' // 发送ajax的服务器地址 // 新域名地址
// axios.defaults.baseURL = 'http://127.0.0.1:9999/app' // 发送ajax的服务器地址
axios.defaults.https = true;
axios.defaults.timeout = 20000; // 请求超时时间，如果请求时间超过
// 20秒，就报错。 20000

// console.log("router", router);
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // 把本地存储的token取出来
    let token = local.get("TOKEN");

    // 请求头携带token字段
    if (token) {
      config.headers["X-Access-Token"] = token;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截
// axios.interceptors.response.use(response => {
//     // 判断 如果有code 和 msg 字段
//     let { code, msg, statusCode } = response.data;

//     if (code === 0) {
//         Message({
//             message: msg,
//             type: "success",
//         });
//     }

//     if (code === 1) {
//         Message.error(msg);
//     }

//     if (statusCode === '51000') {
//         // local.clear()
//         Message({
//             message: "TOKEN失效,请重新登录",
//             type: "warning",
//         });
//         // this.$message.warning('TOKEN失效,请重新登录');
//         // window.location.href = 'http://sjtx.cddmt.cn/#/login'
//         // window.location.href = 'https://sys.cddmt.cn/#/login'
//         // this.$router.push("../views/login/Login.vue");
//         // this.$router.push({
//         //     path: "/login/login",
//         // })
//         // this.$router.push("/login");
//     }

//     // console.log('响应拦截', response.data.statusCode);

//     return response;
// }, err => {

//     console.log('err', err);
//     // 错误处理  如果错误码是401 就跳清除本地 跳转到登录页面
//     if (err.response.data.code === 401) {
//         local.clear()
//         // this.$router.push("/login");
//     }

//     return Promise.reject(err)
// })

// 暴露出去
export default axios;
