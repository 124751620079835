/**
 * call
 * 呼叫
 */

import request from "@/utils/request";

// 查询自动回收策略
export const findOne = (data) => {
  return request({
    method: "post",
    url: "/phone-recycle/findOne",
    data,
  });
};

// 查询自定义表头
export const customFindOne = (data) => {
  return request({
    method: "post",
    url: "/phone-custom/findOne",
    data,
  });
};

// 修改自定义表头
export const customAddUpdate = (data) => {
  return request({
    method: "post",
    url: "/phone-custom/add-update",
    data,
  });
};

// 删除自定义表头设置
export const customDel = (data) => {
  return request({
    method: "post",
    url: "/phone-custom/del",
    data,
  });
};
