/**
 * dzwl
 * 电子围栏 地理围栏 api
 */

import request from '@/utils/request'

// 百度地图
// 操作公司地理围栏

// 百度地图地理围栏查询
export const selectFenceDzwl = (data) => {
    return request({
        method: 'post',
        url: '/fence/selectFence',
        data
    })
}

// 百度地图地理围栏创建
export const createFenceDzwl = (data) => {
    return request({
        method: 'post',
        url: '/fence/createFence',
        data
    })
}

// 百度地图地理围栏修改
export const updateFenceDzwl = (data) => {
    return request({
        method: 'post',
        url: '/fence/updateFence',
        data
    })
}

// 百度地图地理围栏删除
export const deleteFenceDzwl = (data) => {
    return request({
        method: 'post',
        url: '/fence/delete',
        data
    })
}

//  地理围栏下的员工api
// 查询开通员工
export const selectDzwlUserApi = (data) => {
    return request({
        method: 'post',
        url: '/fence/selectUser',
        data
    })
}

// 新增开通员工
export const addDzwlUserApi = (data) => {
    return request({
        method: 'post',
        url: '/fence/addUser',
        data
    })
}

// 删除开通员工
export const deleteDzwlUserApi = (data) => {
    return request({
        method: 'post',
        url: '/fence/deleteUser',
        data
    })
}

// 确定用户在不在经纬度接口
export const confirmUserOnline = (data) => {
    return request({
        method: 'post',
        url: '/fence/countersign',
        data
    })
}

// 封装Amap
export const amap =  ()=> {

    return new Promise(resolve => {

        let url = `https://webapi.amap.com/maps?v=2.0&key=d3ef7ad351416f1e203a88e69180e014&plugin=AMap.CitySearch&callback=AMapLoad`

        let script = document.createElement("script");

        script.src = url;

        document.body.appendChild(script);

        window.AMapLoad = function () {

            resolve(window.AMap)

        }

    })
}