<template>
  <div class="MyBreadcrumb">
    <i class="el-icon-location-information" style="font-size: 18px;margin-right: 4px;"></i>
    <el-breadcrumb separator="/" separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">系统首页</el-breadcrumb-item> -->
      <el-breadcrumb-item v-for="(item, index) in matched" :key="item.path"
        :disabled="index === matched.length - 1">
        <!-- :to="index === matched.length - 1 ? null : { path: item.path }" :disabled="index === matched.length - 1"> -->
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'MyBreadcrumb',
  computed: {
    matched() {
      return this.$route.matched.filter(item => item.meta.title)
    }
  }
}
</script>

<style lang="less" scoped>
.MyBreadcrumb {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: #fff;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}
</style>